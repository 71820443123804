<template>
  <svg viewBox="-240 45 13.8 14.7">
    <g>
      <path class="st0" d="M-227,59l-12-13" />
      <path class="st0" d="M-239,53v-7h7" />
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
}
</style>
