import { useNuxtApp } from "#app/nuxt";
import { nextTick } from "vue";

export default defineNuxtPlugin(nuxtApp => {
  // Using code from here to prevent scroll to top after page hydration
  // node_modules/nuxt/dist/pages/runtime/router.options.js
  nuxtApp.$router.options.scrollBehavior = async (to, from, savedPosition) => {
    const nuxtApp = useNuxtApp();
    let position = savedPosition || void 0;

    if (!position && from && to && to.meta.scrollToTop !== false && _isDifferentRoute(from, to)) {
      position = { left: 0, top: 0 };
    }
    if (to.path === from.path) {
      if (from.hash && !to.hash) {
        return { left: 0, top: 0 };
      }
      if (to.hash) {
        return { el: to.hash, top: _getHashElementScrollMarginTop(to.hash) };
      }
    }
    const hasTransition = (route) => !!(route.meta.pageTransition ?? false);

    const hookToWait = hasTransition(from) && hasTransition(to) ? "page:transition:finish" : "page:finish";

    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce(hookToWait, async () => {
        await nextTick();

        if (to.hash) {
          position = { el: to.hash, top: _getHashElementScrollMarginTop(to.hash) };
        }
        resolve(position);
      });
    });
  }
});

function _getHashElementScrollMarginTop(selector) {
  try {
    const elem = document.querySelector(selector);
    if (elem) {
      return parseFloat(getComputedStyle(elem).scrollMarginTop);
    }
  } catch {
  }
  return 0;
}
function _isDifferentRoute(from, to) {
  const samePageComponent = to.matched.every((comp, index) => comp.components?.default === from.matched[index]?.components?.default);
  if (!samePageComponent) {
    return true;
  }
  if (samePageComponent && JSON.stringify(from.params) !== JSON.stringify(to.params)) {
    return true;
  }
  return false;
}