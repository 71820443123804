import { COUNTRY_LOCALES } from "~/constants/global";
import qs from 'qs';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    return;
  }

  const countryLocales = COUNTRY_LOCALES;
  const oldLocales = {
    // 'de-de': 'de',
    // 'it-it': 'it',
    'es-es': 'es',
    // 'fr-fr': 'fr',
    // 'fr-ca': 'ca',
    // 'en-ca': 'ca',
    // 'en-au': 'au',
    'en-gb': 'uk',
  };

  const categoriesList = [
    'approach-shoes',
    'badminton-shoes',
    'baseball-cleats',
    'basketball-shoes',
    'climbing-footwear',
    'climbing-shoes',
    'cycling-shoes',
    'football-cleats',
    'golf-shoes',
    'hiking-boots',
    'hiking-footwear',
    'hiking-sandals',
    'hiking-shoes',
    'mountaineering-boots',
    'running-shoes',
    'sneakers',
    'soccer-cleats',
    'tennis-shoes',
    'track-and-field-shoes',
    'training-shoes',
    'volleyball-shoes',
    'walking-shoes',
    'wrestling-shoes',
  ];

  const path = to.path || '';

  // redirect to lowercase
  if (path.match(/.*[A-Z].*/)) {
    return navigateTo(path.toLowerCase(), { redirectCode: 301 });
  }

  if (
    path === '/catalog/black-friday-shoes-deals' ||
    path === '/catalog/cyber-monday-shoes-deals'
  ) {
    return navigateTo('/deals', { redirectCode: 301 });
  }

  if (path === '/sitemap.xml' || path === '/sitemap_index.xml') {
    return navigateTo('/sitemaps/index.xml', { redirectCode: 301 });
  }

  let newPath = path;
  let locale = path.match(/^\/[a-z]{2}(-[a-z]{2})?($|\/)/);

  if (locale && locale.length > 0) {
    locale = locale[0].replace(/\//g, '');

    if (oldLocales[locale]) {
      newPath = newPath.replace('/' + locale, '/' + oldLocales[locale]);
    } else if (!countryLocales[locale]) {
      newPath = newPath.replace('/' + locale, '');
    }
  }

  let ranking = newPath.match(/^\/ranking\/rankings-of-/);

  if (ranking && ranking.length > 0) {
    newPath = newPath.replace('ranking/rankings-of-', 'catalog/');
  }

  let bestRoute = newPath.match(/^\/best-/);
  if (bestRoute && bestRoute.length > 0) {
    newPath = newPath.replace('best-', 'catalog/');
  }

  if (path === '/reviews') {
    return navigateTo('/', { redirectCode: 301 });
  }

  const reviews = newPath.match(/^\/reviews\//);

  if (reviews && reviews.length > 0) {
    const categoriesRegexp = categoriesList.join(')|(');
    const categoriesMatch = newPath.match(`^/reviews/((${categoriesRegexp}))`);
    if (categoriesMatch && categoriesMatch.length > 0) {
      newPath = newPath.replace('/reviews/', '/catalog/');
      return navigateTo(newPath, { redirectCode: 301 }); // No need to apply GET params here
    }

    newPath = newPath.replace('/reviews/', '/');
  }

  if (path !== newPath) {
    await $api(`/api/pages/redirect`, { params: { path: newPath } })
      .then(response => {
        if (response.redirect && response.redirect.to) {
          newPath = response.redirect.to;
        }
      })
      .catch(error => {});

    const append = qs.stringify({
      ...to.query,
      page: to.query.page && parseInt(to.query.page, 10) < 2 ? undefined : to.query.page,
    });

    return navigateTo(append ? newPath + '?' + append : newPath, { redirectCode: 301 });
  }
})
