<template>
  <svg :width="width" :height="height" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 571.4 1000">
    <path
      d="M332 536q0 7-5 12L66 809q-5 5-12 5t-13-5l-28-28q-6-6-6-13t6-13l219-219L13 316q-6-5-6-12t6-13l28-28q5-6 13-6t12 6l261 260q5 5 5 13zm214 0q0 7-5 12L281 809q-6 5-13 5t-13-5l-28-28q-5-6-5-13t5-13l219-219-219-220q-5-5-5-12t5-13l28-28q6-6 13-6t13 6l260 260q5 5 5 13z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '9.14',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  position: relative;
  top: 2px;
}
</style>
