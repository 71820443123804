import ThePage from "~/components/shared/layout/ThePage";
import TheModal from "~/components/core/TheModal";
import TheImage from "~/components/core/TheImage";
import DropDownSelect from "~/components/core/DropDownSelect";
import StarsRating from "~/components/core/StarsRating";
import CtrButton from "~/components/core/CtrButton";
import AngleLeftIcon from "~/components/icons/awesome-font/AngleLeftIcon";
import AngleRightIcon from "~/components/icons/awesome-font/AngleRightIcon";
import AngleDoubleRightIcon from "~/components/icons/awesome-font/AngleDoubleRightIcon";
// import Popover from "~/components/core/Popover";
import { Popover } from "uiv";
import { LazyHydrationWrapper } from "vue3-lazy-hydration";
// import VueAwesomePaginate from "vue-awesome-paginate";
// import "vue-awesome-paginate/dist/style.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('ThePage', ThePage);
  nuxtApp.vueApp.component('TheModal', TheModal);
  nuxtApp.vueApp.component('TheImage', TheImage);
  nuxtApp.vueApp.component('StarsRating', StarsRating);
  nuxtApp.vueApp.component('DropDownSelect', DropDownSelect);
  nuxtApp.vueApp.component('CtrButton', CtrButton);
  nuxtApp.vueApp.component('Survey', defineAsyncComponent(() => import('~/components/shared/Survey')));

  nuxtApp.vueApp.component('AngleLeftIcon', AngleLeftIcon);
  nuxtApp.vueApp.component('AngleRightIcon', AngleRightIcon);
  nuxtApp.vueApp.component('AngleDoubleRightIcon', AngleDoubleRightIcon);

  nuxtApp.vueApp.component('Popover', Popover);

  // nuxtApp.vueApp.use(VueAwesomePaginate);
  nuxtApp.vueApp.component('LazyHydrate', LazyHydrationWrapper);

  // Use this swiper implementation if we need it - https://swiperjs.com/element
});
