<template>
  <nav aria-label="breadcrumb" v-if="breadcrumbs && breadcrumbs.length > 0">
    <ul class="breadcrumb" :class="{ 'container-fixed': fixedContainer }">
      <li
        class="breadcrumb-item"
        v-for="(link, index) in breadcrumbs"
        :class="{ active: index === (breadcrumbs.length - 1) }"
        v-bind="{'aria-current': index === (breadcrumbs.length - 1) ? 'page' : null }"
      >
        <span v-if="index === (breadcrumbs.length - 1)">{{ link.name }}</span>
        <a v-else :href="link.url">{{ link.name }}</a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { useMainStore } from "~/stores/main";

const main = useMainStore();

const breadcrumbs = computed(() => main.breadcrumbs);
const fixedContainer = computed(() => main.fixedContainer);
</script>

<style lang="scss" scoped>
.breadcrumb {
  position: relative;
  top: -3px;
  font-size: 12px;
  font-weight: 300;
  padding: 0;
  margin-bottom: 3px;
  list-style: none;
  background-color: #fff;
  border-radius: 0;

  & > .active {
    color: #000;
  }

  &.container-fixed {
    @media (min-width: $break_md_upper) {
      margin: auto;
      max-width: 960px;
    }
  }
}

.breadcrumb-item {
  & > a {
    color: #777;
  }
}
</style>
