<template>
  <svg :height="height" :width="width" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.1 1000">
    <path
      d="M332 536q0 7-5 12L66 809q-5 5-12 5t-13-5l-28-28q-6-6-6-13t6-13l219-219L13 316q-6-5-6-12t6-13l28-28q5-6 13-6t12 6l261 260q5 5 5 13z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '14.28px',
    },
    height: {
      type: String,
      default: '40px',
    },
    fill: {
      type: String,
      default: null,
    },
  },
};
</script>
