<template>
  <div class="main-search">
    <form :action="localePath({ name: 'search'})" @submit.capture.prevent="onEnterPress">
      <input
        ref="searchInput"
        :value="query"
        @input="onKeypress"
        @keypress="onKeypress"
        @keydown.down="onDown"
        @keydown.up="onUp"
        type="text"
        autocomplete="off"
        name="q"
        :placeholder="placeholder ? placeholder : $t('generic.search_placeholder')"
        @focus="activate"
        @blur="inactivate"
      />
      <a
        class="search"
        v-if="displaySearchLink"
        :href="searchLink"
        @mousedown="onEnterPress"
        @click.prevent.stop
      >
        <svg height="23px" viewBox="28 60 30 40" width="23px">
          <use xlink:href="#search" />
        </svg>
      </a>

      <!-- search suggestion -->

      <ul v-if="(suggestions.length > 0 || error) && active" id="search-suggestions">
        <li v-if="error" class="error">{{ $t('generic.error_try_again') }}</li>
        <li
          v-for="(suggestion, index) in suggestions"
          :key="index"
          :class="{ 'suggestion-w-image': suggestion.image, 'selected': index === selectedSuggestion }"
        >
          <a
            class="search-suggestion"
            v-if="suggestion.link"
            :href="suggestion.link"
            @mousedown.prevent
            @click.capture="inactivate"
          >
            <img v-if="suggestion.image" class="suggestion-image" :src="suggestion.image" alt="" />
            <span class="suggestion-content" v-html="suggestion.value" />
            <!-- svg icon -->
            <SearchArrowIcon />
            <!-- end svg icon -->
          </a>
          <div v-else @click="selected(suggestion)" class="search-suggestion">
            <img v-if="suggestion.image" class="suggestion-image" :src="suggestion.image" alt="" />
            <span class="suggestion-content" v-html="suggestion.value" />
          </div>
        </li>
      </ul>
    </form>
    <div v-if="active" class="search-background" />
  </div>
</template>

<script>
import SearchArrowIcon from '~/components/icons/SearchArrowIcon.vue';
import { mapState } from "pinia";
import { useSearchStore } from "~/stores/search";
// import debounce from '~/utils/debounce';

export default {
  components: {
    SearchArrowIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: Number,
      default: null,
    },
    onlyShoes: {
      type: Boolean,
      default: false,
    },
    displaySearchLink: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    addBlurOnPageShow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['init', 'disactivated'],
  data() {
    return {
      query: '',
      active: false,
      error: false,
      selectedSuggestion: null,
    };
  },
  computed: {
    searchLink() {
      if (this.selectedSuggestion === null) {
        return this.localePath({name: 'search'})+`?q=${this.query}`;
      }
      
      if (!this.suggestions.length) {
        return this.localePath({name: 'search'})+`?q=${this.query}`;
      }

      return this.suggestions.length
        ? this.suggestions[this.selectedSuggestion]?.link
        : this.localePath({name: 'search'})+`?q=${this.query}`;
    },
    ...mapState(useSearchStore, ['suggestions'])
  },
  watch: {
    isActive: function () {
      if (this.isActive) {
        this.active = true;
        this.$refs.searchInput.focus();
      }
    },
    value(queryValue) {
      if (this.query !== queryValue) {
        this.query = queryValue;
        this.onQueryChange();
      }
    },
  },
  mounted() {
    this.$emit('init');

    if (this.addBlurOnPageShow) {
      window.addEventListener('pageshow', evt => {
        this.$refs.searchInput.blur();
      });
    }
  },
  methods: {
    onQueryChange: debounce(async function() {
      const searchStore = useSearchStore();

      // reset error.
      this.error = false;

      // Always empty last search...
      searchStore.reset();

      //  Filter empty queries
      if (this.query && this.query.length > 2) {
        try {
          await searchStore.suggest({
            query: this.query,
            onlyShoes: this.onlyShoes,
            category: this.categoryId,
          });
        } catch (e) {
          this.error = true;
          console.log(e)
        }
      }
    }, 200),
    onEnterPress() {
      console.log(this.searchLink);
      window.location.href = this.searchLink;
      // setTimeout(() => this.$router.push(this.searchLink), 205);
      // this.inativate({ emit: true, blur: true });
    },
    activate() {
      this.active = true;
    },
    inactivate({ emit = true, blur = false }) {
      this.active = false;
      if (blur) {
        this.$refs.searchInput.blur();
      }
      if (emit) {
        this.$emit('disactivated');
      }
    },
    selected(shoe) {
      console.log(shoe);
    },
    onKeypress: function(event) {
      this.query = event.target.value;
      this.onQueryChange();
    },
    onDown(event) {
      if (!this.suggestions.length) {
        return;
      }

      if (this.selectedSuggestion === null) {
        this.selectedSuggestion = 0;
        return;
      }
      
      if (this.selectedSuggestion < this.suggestions.length - 1) {
        this.selectedSuggestion++;
      } else {
        this.selectedSuggestion = 0;
      }
    },
    onUp(event) {
      if (!this.suggestions.length) {
        return;
      }

      if (this.selectedSuggestion === null) {
        this.selectedSuggestion = this.suggestions.length - 1;
        return;
      }

      if (this.selectedSuggestion > 0) {
        this.selectedSuggestion--;
      } else {
        this.selectedSuggestion = this.suggestions.length - 1;
      }

    }
  },
};
</script>

<style lang="scss" scoped>
div.main-search {
  display: inline-block;
  width: 664px;
  @media (max-width: $break_sm_under) {
    width: 98%;
  }
  form {
    position: relative;
    height: 56px;
    width: 100%;
    z-index: 2;
    white-space: nowrap;
    input[type='text'] {
      width: calc(100% - 55px);
      display: inline-block;
      height: 100%;
      vertical-align: top;
      padding: 0 20px;
      margin-right: -4px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border: 1px solid #fff;
      &:focus {
        outline: 0;
      }
    }
    .search {
      display: inline-block;
      height: 100%;
      width: 55px;
      background: $orange;
      border: none;
      color: #fff;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    #search-suggestions {
      top: 60px;
      left: 0;
      position: absolute;
      background: #fff;
      border-radius: 2px;
      border: 1px solid #fff;
      width: 100%;
      list-style: none;
      padding-left: 0;
      li {
        height: 32px;
        //width: 22px;
        background: #fff;
        width: 100%;
        text-align: left;
        padding: 0;
        border-top: 1px solid $grey-dark;
        &.error {
          color: $error_text_color;
          padding: 5px;
        }
        &.suggestion-w-image {
          height: 60px;
        }
        &:first-child {
          margin-top: 5px;
          border-top: none;
        }
        &:last-of-type {
          border-bottom: none;
        }
        & + .suggestion-group-label {
          border-top: none;
        }
        &.suggestion-group-label {
          margin-top: 10px;

          span {
            height: 100%;
            width: 100%;
            line-height: 32px;
            display: inline-block;
            padding: 0 20px;
            //color: #000;
            color: $grey;
          }
        }
        &.selected {
          background-color: $grey-dark;
        }

        .search-suggestion {
          height: 100%;
          width: 100%;
          line-height: 32px;
          display: inline-block;
          padding: 0 20px;
          color: #000;
          position: relative;
          &:hover {
            text-decoration: none;
            span.suggestion-content {
              text-decoration: underline;
            }
          }
          span.suggestion-content {
            span.suggestion {
              //color: $grey-dark;
              color: #000;
              font-weight: 300;
              .query {
                color: #000;
              }
              .category {
                font-weight: bold;
                color: #000;
              }
            }
          }
          img.suggestion-image {
            // display: none;
            margin-right: 10px;
            // height: 22px;
          }
        }

        :deep(.search-suggestion) {
          svg {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            height: 14px;
            width: 14px;
          }
        }
      }
    }
  }
  .search-background {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.5;
  }
  &[data-expandable='true'] {
    width: 100%;
  }
}

nav.navbar {
  div.main-search {
    display: inline-block;
    // width: calc(100% - 531px);
    width: calc(100% - 615px);
    margin-left: 20px;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    transition: width 0.25s ease;

    @media (max-width: $break_sm_under) {
      top: 5px;
    }

    @media (max-width: $break_md_under) {
      position: absolute;
      z-index: 10000;
      left: -15px;
      top: -15px !important;
      width: 100px;
      padding: 0;
      margin: 0;
      visibility: hidden;
      &.expanded {
        display: inline-block;
        width: 100%;
        visibility: visible;
      }
    }
    @media (min-width: $break_sm_upper) and (max-width: $break_md_under) {
      top: 0 !important;
    }
    form {
      margin: 11px 15px;
      padding: 0;
      width: 100%;
      height: 45px;
      position: relative;

      #search-suggestions {
        top: 47px;
      }
    }
  }
}
</style>
