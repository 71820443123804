<template>
  <footer id="footer" class="site-footer">
    <div class="container">
      <div class="links">
        <div v-for="links in footerLinks" class="links-row">
          <template v-for="(link, index) in links">
            <a :href="link.url" v-text="link.name"></a><span v-if="index < links.length - 1">&nbsp; | &nbsp;</span>
          </template>
        </div>
      </div>
      <LanguageSwitcher class="language" />
      <div>
        {{ $t('generic.copyright') }} © {{ currentYear }}
        <a :href="localePath({ name: 'index' })">
          RunRepeat.com
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup>
import LanguageSwitcher from '~/components/shared/layout/LanguageSwitcher';
import { usePageStore } from "~/stores/page";

const page = usePageStore();

const currentYear = computed(() => new Date().getFullYear());
const footerLinks = computed(() => page.global.footer_links);
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  z-index: 3;
  background-color: $dark-blue;
  color: $dark-blue-font-color;
  padding: 15px 0;
  position: relative;
  z-index: 997;

  .container {
    display: flex;
    flex-flow: column;
    row-gap: 15px;
    text-align: center;

    @media (min-width: 992px) {
      flex-flow: row;
      flex-direction: row-reverse;
      align-items: center;

      .links {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-left: auto;
        text-align: right;
      }

      .language {
        margin-left: 16px;
      }
    }
  }
  a {
    // color: rgba(255, 255, 255, 0.35);
    color: #fff;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>
