<template>
  <svg :height="height" :width="width" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.1 1000">
    <path
      d="M350 304q0 7-6 12L125 536l219 219q6 6 6 13t-6 13l-28 28q-5 5-12 5t-13-5L31 548q-6-5-6-12t6-13l260-260q5-6 13-6t12 6l28 28q6 5 6 13z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '14.28px',
    },
    height: {
      type: String,
      default: '40px',
    },
    fill: {
      type: String,
      default: null,
    },
  },
};
</script>
