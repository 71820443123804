import performanceMonitoring from "~/utils/performanceMonitoring";
import { usePageStore } from "~/stores/page";
import { useMainStore } from "~/stores/main";
import { useCookie } from "#app";
import { useShopsStore } from "~/stores/shops";
import { useColorsStore } from "~/stores/colors";
import { useProductsStore } from "~/stores/products";
import { useGuideStore } from "~/stores/guide";
import { useOffersStore } from "~/stores/offers";
import { useExperimentStore } from "~/stores/experiment";

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp;
  const main = useMainStore();
  const page = usePageStore();
  const experiment = useExperimentStore();

  onNuxtReady(() => {
    if (page.seoGroup !== null) {
      useCookie('seo_group', {
        maxAge: 24 * 3600,
        path: '/',
      }).value = page.seoGroup;
    }
  })
  
  return { provide: { gaEvents : {
    isEnabled() {
      return window
        && (main.isProd
          || /runrepeat\.com$/i.test(window.location.hostname)
          || /[?&]test_GA=/.test(window.location.search)
        )
        && !/[?&]uptime_test=/.test(window.location.search)
    },
    getDimensions(properties) {
      const dimensions = [
        { field: 'dimension1', value: properties.cached },
        { field: 'dimension2', value: properties.country },
        { field: 'dimension3', value: properties.search_volume_group },
        { field: 'dimension4', value: properties.layout },
        { field: 'dimension5', value: properties.page_type },
        { field: 'dimension10', value: properties.author },
        { field: 'dimension12', value: properties.category },
      ];

      return dimensions;
    },
    getPageProperties() {
      const timings = performanceMonitoring.getServerTimings();

      const experiment = useExperimentStore();

      const properties = {
        cached: timings.has('hit') ? 'yes' : (timings.has('miss') ? 'no' : ''),
        country: main.countryCode,
        layout: page.layout,
        page_type: page.ga.page,
        author: page.ga.author,
        category: page.ga.category,
        search_volume: page.searchVolume,
        search_volume_group: this.searchVolumeGroup(),
        experiment: experiment.isActive ? experiment.id + '_' + experiment.variation : '',
      };

      if (timings.has('api') && timings.get('api').duration) {
        properties.api_time = timings.get('api').duration;
      }

      if (timings.has('total') && timings.get('total').duration) {
        properties.total_time = timings.get('total').duration;
      }

      performanceMonitoring.getUserTimings().forEach((value, key) => {
        properties[key] = Math.round(value);
      });

      return properties;
    },
    searchVolumeGroup() {
      const searchVolume = page.searchVolume;
      const pageType = page.ga.page;

      let prefix = '';

      if (pageType === 'shoe' || pageType === 'product') {
        prefix = 'P';
      }

      if (pageType === 'product_upcoming') {
        prefix = 'U';
      }

      if (pageType === 'ranking'
        || pageType === 'catalog'
        || pageType === 'shoes_filter'
      ) {
        prefix = 'C';
      }

      if (pageType === 'buying_guide') {
        prefix = 'B';
      }

      if (!prefix) {
        return '';
      }

      if (searchVolume < 100) {
        return prefix+'SV0';
      }

      if (searchVolume < 500) {
        return prefix+'SV100';
      }

      if (searchVolume < 1000) {
        return prefix+'SV500';
      }

      return prefix+'SV1000';
    },
    sendPageview(referrer) {
      if (!this.isEnabled() || !page.type || !app.$gtag) {
        return;
      }

      const properties = this.getPageProperties();

      properties.page_path = window.location.pathname + window.location.search;
      properties.page_title = page.meta.title;
      properties.page_location = window.location.href;

      if (referrer) {
        properties.page_referrer = referrer;
      }

      const performance = window.performance || window.webkitPerformance;
      const pt = performance && performance.timing;

      if (pt && pt.navigationStart > 0 && pt.loadEventStart > 0) {
        properties.page_load_time = pt.loadEventStart - pt.navigationStart;
        properties.page_download_time = pt.responseEnd - pt.responseStart;
        properties.dns_time = pt.domainLookupEnd - pt.domainLookupStart;
        properties.redirect_response_time = pt.fetchStart - pt.navigationStart;
        properties.server_response_time = pt.responseStart - pt.requestStart;
        properties.tcp_connect_time = pt.connectEnd - pt.connectStart;
        properties.dom_interactive_time = pt.domInteractive - pt.navigationStart;
        properties.content_load_time = pt.domContentLoadedEventStart - pt.navigationStart;
      }

      app.$gtag.pageview(properties);

      if (experiment.isActive) {
        app.$gtag.event('experience_impression', {
          exp_variant_string: experiment.ga4Variation,
        });
      }
    },
    sendEvent(category, action, label = '', value = 0) {
      return;
      // console.log(category+'-'+action+'-'+label);
      if (!this.isEnabled() || !category) {
        return;
      }

      const event = {
        eventCategory: category,
        eventAction: action,
      };

      if (label) {
        event.eventLabel = label;
      }

      if (value) {
        event.eventValue = !isNaN(parseFloat(value)) && isFinite(value) ? Math.round(value) : value;
      }

      try {
        app.$ga.event(event);
      } catch (e) {
        console.log('ga: request failed');
      }
    },
    sendGaEvent(action, eventCategory, params = {}) {
      if (!app.$gtag || !action || !this.isEnabled()) {
        return;
      }

      if (params.value) {
        params.value = !isNaN(parseFloat(params.value)) && isFinite(params.value) ? Math.round(value) : value;
      }

      if (eventCategory) {
        params.eventCategory = eventCategory;
      }

      try {
        app.$gtag.event(action, {
          locale: main.locale,
          country: main.countryCode,
          category: page.ga.category,
          gender: main.gender,
          size: main.size,
          width: main.width,
          page_type: page.ga.page,
          search_volume: page.searchVolume,
          search_volume_group: this.searchVolumeGroup(),
          ...params,
        });
      } catch (e) {
        console.log('ga4: request failed');
      }
    },
    sendOfferClicked(secondaryEvent, offer, productId, productName, isAnyColor) {
      const shop = useShopsStore().shopById(offer.shop_id);
      const eventCategory = 'OfferClicks';

      const params = {
        shop: shop.name,
        shop_id: offer.shop_id,
        shop_ranking: shop.ranking,
        product_id: productId,
        product: productName,
        any_color: isAnyColor ? 'yes' : 'no',
        price: offer.lowest_price_usd ? (offer.lowest_price_usd/100) : (offer.price || 0),
      };

      this.sendGaEvent('OfferClicked', eventCategory, params);
      this.sendGaEvent(secondaryEvent, eventCategory, params);
    },
    sendPriceComparisonEvent(action, params)
    {
      this.sendGaEvent(action, 'SectionPriceComparison', {
        any_color: useColorsStore().current.id ? 'no' : 'yes',
        ...this.productParams(),
        ...params
      });
    },
    sendPriceEvent(shopId, shopSuffix = '', category = '', productName = '') {
      const shop = useShopsStore().shopById(shopId);

      const shopName = shop.ga_name + shopSuffix;

      const value = Math.round(shop.ranking * 100);

      const label = productName || this.productName();

      const eventCategory = category || 'PCRetailers';

      this.sendEvent(eventCategory, shopName, label, value);

      if (eventCategory === 'PCRetailers') {
        const country = main.countryCode;
        this.sendEvent('PCRetailersPerCountry', 'Country', `${country} - ${label}`, value);
      }

      this.sendEvent('RetailersCombined', shopName, productName, value);
    },
    headerLinkClicked(url) {
      this.sendEvent('headerLink', 'click', url);
    },
    productName() {
      const product = useProductsStore().current;
      return product ? product.name : '';
    },
    productParams() {
      const product = useProductsStore().current;
      return {
        product_id: product.id || 0,
        product: product.name || '',
      };
    },
    guideName() {
      return useGuideStore().guideTitleWithoutBest || '';
    },
    fullGender(value = '') {
      if (!value) {
        value = main.gender;
      }

      return value === 'w' ? 'Women' : 'Men';
    },
    productFilterChanged(filterName, filterValue) {
      const productName = this.productName();

      this.sendEvent('PCOverall', `${filterName}Select`, productName);

      if (filterName === 'Gender') {
        filterName = this.fullGender(filterValue);
      }

      this.sendEvent('SectionPriceComparison', filterName, productName);

      if (filterName === 'Size') {
        this.sendEvent('SelectedSize', this.fullGender(), filterValue);
      }

      if (filterName === 'Width') {
        this.sendEvent('SelectedWidth', this.fullGender(), filterValue);
      }

      if (filterName === 'Color') {
        this.sendEvent('SectionImage', `Color ${filterValue}`, productName);
      }

      this.sendPriceComparisonEvent('ProductFilterChanged', {
        filter_name: filterName,
        filter_value: filterValue,
      });
    },
    productOfferClicked(offer = null, position = 0, pcPosition = 'top') {
      const productName = this.productName();

      const source = main.stickyPc
        ? 'BuySectionSidebar'
        : 'BuySection' + pcPosition.charAt(0).toUpperCase() + pcPosition.slice(1);
      const colorSelected = !!useColorsStore().current.id;

      // Event 1
      this.sendEvent('PCOverall', source, productName);

      // Event 2
      this.sendEvent('PCOverall', 'BuyColor' + (colorSelected ? 'Yes' : 'No'), productName);

      if (!offer || !offer.shop_id) {
        return;
      }

      let shopSuffix = '';

      if (offer.in_stock && !offer.price) {
        shopSuffix = ' - check stock';
      } else if (!offer.in_stock) {
        shopSuffix = ' - no stock';
      }

      this.sendPriceEvent(offer.shop_id, shopSuffix);

      const productParams = this.productParams();

      this.sendOfferClicked(
        'ProductPageOfferClicked',
        offer,
        productParams.product_id,
        productParams.product,
        !!useColorsStore().current.id
      );

      this.sendPriceComparisonEvent('PriceComparisonOfferClicked', {
        offer_type: !offer.in_stock
          ? 'no_stock'
          : (offer.price ? 'in_stock' : 'check_stock'),
        offer_position: position,
        comparison_position: pcPosition,
        ...productParams,
      });

      this.sendEvent(
        'WidthOfferClicked',
        main.gender.toUpperCase() + '-' + main.width,
        productName,
      );

      if (position && position > useOffersStore().top.length) {
        this.sendEvent('OfferFromMoreClicked', position.toString(), productName);
      }
    },
    productMobileDealClicked(offer, position, isSizeSelected) {
      const action = isSizeSelected ? `Cta${position}Retailer` : `Cta${position}Available`;
      this.sendEvent('SectionPriceComparison', action, this.productName());
      this.sendPriceEvent(offer.shop_id);

      const productParams = useProductsStore().current;
      this.sendOfferClicked(
        'ProductPageOfferClicked',
        offer,
        productParams.product_id,
        productParams.product,
        !!useColorsStore().current.id
      );

      this.sendPriceComparisonEvent('MobileDealClicked', {
        mobile_deal_position: position,
        mobile_deal_type: isSizeSelected ? 'Retailer' : 'Available',
      });
    },
    productMoreShopsOpened() {
      this.sendEvent('PCOverall', 'MoreShopsOpened', this.productName());
      this.sendPriceComparisonEvent('ProductMoreShopsOpened', {});
    },
    productPriceHistoryOpened() {
      this.sendEvent('PCOverall', 'PriceHistory', this.productName());
      this.sendPriceComparisonEvent('PriceHistory', {});
    },
    productPriceHistoryOfferClicked(offer) {
      this.sendEvent('SectionPriceHistory', 'LowestPrice', this.productName());
      this.sendPriceEvent(offer.shop_id);

      this.sendPriceComparisonEvent('PriceHistoryOfferClicked', {});

      const productParams = useProductsStore().current;
      this.sendOfferClicked(
        'ProductPageOfferClicked',
        offer,
        productParams.product_id,
        productParams.product,
        !!useColorsStore().current.id
      );
    },
    productPriceAlertOpened(source = '') {
      this.sendEvent('PCOverall', 'PriceAlert', this.productName());
      this.sendPriceComparisonEvent('PriceAlert', {
        price_alert_source: source,
      });

      if (source) {
        this.sendEvent('PCOverall', `${source}Show`, this.productName());
      }
    },
    productPriceAlertSubmitted(source = '') {
      this.sendEvent('PCOverall', 'PriceAlertSubmit', this.productName());
      this.sendPriceComparisonEvent('PriceAlertSubmit', {
        price_alert_source: source,
      });

      if (source) {
        this.sendEvent('PCOverall', `${source}Submit`, this.productName());
      }
    },
    productCompetitionPopup(productName) {
      this.sendEvent('SectionCompetition', 'ShowPopup', this.productName());
      this.sendGaEvent('CompetitionPopup', 'SectionCompetition', this.productParams());
    },
    productCompetititonCopyUrl() {
      this.sendEvent('SectionCompetition', 'SuccessCopyUrl');
      this.sendGaEvent('CompetitionCopyUrl', 'SectionCompetition', this.productParams());
    },
    productCompetititonShareFacebook() {
      this.sendEvent('SectionCompetition', 'SuccessShareFacebook');
      this.sendGaEvent('CompetitionShareFacebook', 'SectionCompetition', this.productParams());
    },
    productCompetititonShareTwitter() {
      this.sendEvent('SectionCompetition', 'SuccessShareTwitter');
      this.sendGaEvent('CompetitionShareTwitter', 'SectionCompetition', this.productParams());
    },
    productCompetitionSignup() {
      this.sendEvent('SectionCompetition', 'SignedUp', this.productName());
      this.sendEvent('CompetitionSignup', 'ProductPage', this.productName());
      this.sendGaEvent('CompetitionSignup', 'SectionCompetition', {
        competition_source: 'product_page',
        ...this.productParams(),
      });
    },
    productCompetitionPageSignup(productName) {
      this.sendEvent('SectionCompetition', 'SignedUp', productName);
      this.sendEvent('CompetitionSignup', 'CompetitionPage', productName);
      this.sendGaEvent('CompetitionSignup', 'SectionCompetition', {
        competition_source: 'competition_page',
        ...this.productParams(),
      });
    },
    productNewProductSubscribed() {
      this.sendEvent('NewProductSection', 'AlertSubmit', this.productName());
      this.sendGaEvent('NewProductSignup', 'NewProductSection', this.productParams());
    },
    productExploreSectionClicked(sectionName, productName) {
      this.sendEvent('SectionExplore', sectionName, productName);
      this.sendGaEvent('ProductExploreClicked', 'SectionExplore', {
        explore_section: sectionName,
        ...this.productParams(),
      });
    },
    productCorescoreReviewsPopoverOpened() {
      this.sendEvent('SectionCorescore', 'Ratings', this.productName());
      this.sendGaEvent('CorescoreClicked', 'SectionCorescore', {
        score_part: 'reviews',
        ...this.productParams(),
      });
    },
    productCorescoreMoreClicked() {
      this.sendEvent('SectionCorescore', 'More', this.productName());
      this.sendGaEvent('CorescoreClicked', 'SectionCorescore', {
        score_part: 'more',
        ...this.productParams(),
      });
    },
    productImagesAngleSelected(position) {
      this.sendEvent('SectionImages', `AngleSelected - ${position}`, this.productName());
      this.sendGaEvent('ImageAngleSelected', 'SectionImages', {
        angle_position: position,
        ...this.productParams(),
      });
    },
    productImagesZoomActivated() {
      this.sendEvent('SectionImages', 'ZoomActivated', this.productName());
      this.sendGaEvent('ImageZoomActivated', 'SectionImages', this.productParams());
    },
    productImageSelected(position) {
      this.sendEvent('SectionImages', `ImageSelected - ${position}`, this.productName());
      this.sendGaEvent('ImageSelected', 'SectionImages', {
        position: position,
        ...this.productParams(),
      });
    },
    productNewLabelCorescoreClicked() {
      this.sendEvent('SectionNewLabel', 'Corescore', this.productName());
      this.sendGaEvent('CorescoreClicked', 'SectionCorescore', {
        score_part: 'new_label',
        ...this.productParams(),
      });
    },
    productRatingSubmitted(value) {
      this.sendEvent('SectionRatings', 'Submit', this.productName());
      this.sendGaEvent('ProductRatingSubmit', 'SectionRatings', {
        product_rating: value,
        ...this.productParams(),
      });
    },
    productFactsPriceClicked() {
      this.sendPriceEvent('SectionFacts', 'Price', this.productName());
      this.sendGaEvent('FactsPriceClicked', 'SectionFacts', this.productParams());
    },
    productFactsExpand(isExpanded) {
      const action = 'Show ' + (isExpanded ? 'more' : 'less');
      this.sendEvent('SectionFacts', action, this.productName());
      this.sendGaEvent('Facts'+action, 'SectionFacts', this.productParams());
    },
    productFactsQuestionClicked() {
      this.sendEvent('SectionFacts', 'Question', this.productName());
      this.sendGaEvent('FactsQuestionClicked', 'SectionFacts', this.productParams());
    },
    productFactsLinkClicked(slug) {
      if (slug) {
        this.sendEvent('SectionFacts', `Ranking - ${slug}`, this.productName());
        this.sendGaEvent('FactsLinkClicked', 'SectionFacts', {
          facts_slug: slug,
          ...this.productParams(),
        });
      }
    },
    productSpecialEditionsOpened() {
      this.sendEvent('SectionFacts', 'Special Editions', this.productName());
      this.sendGaEvent('FactsSpecialEditionsClicked', 'SectionFacts', this.productParams());
    },
    productRankingsLinkClicked(name) {
      if (name) {
        this.sendEvent('SectionRankings', name, this.productName());
        this.sendGaEvent('RankingLinkClicked', 'SectionRankings', {
          ranking_name: name,
          ...this.productParams(),
        });
      }
    },
    productReadyToBuyClicked() {
      this.sendEvent('SectionBottomCTA', 'ReadyToBuy', this.productName());
      this.sendGaEvent('BottomCtaButtonClicked', 'SectionBottomCTA', {
        cta_button_type: 'ready_to_buy',
        ...this.productParams(),
      });
    },
    productNotSureClicked() {
      this.sendEvent('SectionBottomCTA', 'NotSure', this.productName());
      this.sendGaEvent('BottomCtaButtonClicked', 'SectionBottomCTA', {
        cta_button_type: 'not_sure',
        ...this.productParams(),
      });
    },
    productSimilarShowMore(expanded) {
      this.sendEvent(
        'SectionCompare',
        expanded ? 'Expanded' : 'Collapsed',
        this.productName(),
      );
      this.sendGaEvent('CompareShowMore', 'SectionCompare', {
        show_more_direction: expanded ? 'more' : 'less',
        ...this.productParams(),
      });
    },
    productSimilarProductClicked(productName, position) {
      this.sendEvent(
        'SectionCompare',
        `Similar shoe ${position}`,
        productName
      );
      this.sendGaEvent('CompareProductClicked', 'SectionCompare', {
        compare_product: productName,
        compare_position: position,
        ...this.productParams(),
      });
    },
    productSimilarProductRemoved(productName, position) {
      this.sendEvent(
        'SectionCompare',
        `ProductRemoved ${position}`,
        productName
      );
      this.sendGaEvent('CompareProductRemoved', 'SectionCompare', {
        compare_product: productName,
        compare_position: position,
        ...this.productParams(),
      });
    },
    productSimilarSearchClicked(location = 'Product') {
      this.sendEvent(
        'SectionCompare',
        `SearchClicked - ${location}`,
        this.productName(),
      );
      this.sendGaEvent('CompareSearchClicked', 'SectionCompare', {
        compare_search_location: location,
        ...this.productParams(),
      });
    },
    productSimilarSameBrandClicked() {
      this.sendEvent(
        'SectionCompare',
        `SameBrandClicked`,
        this.productName(),
      );
      this.sendGaEvent('CompareSameBrandClicked', 'SectionCompare', this.productParams());
    },
    guideSimilarShowMore(expanded) {
      this.sendEvent(
        'GuideSectionCompare',
        expanded ? 'Expanded' : 'Collapsed',
        this.guideName(),
      );
      this.sendGaEvent('GuideCompareShowMore', 'GuideSectionCompare', {
        show_more_direction: expanded ? 'more' : 'less',
        guide: this.guideName(),
      });
    },
    guideSimilarProductClicked(productName, position) {
      this.sendEvent(
        'GuideSectionCompare',
        `Similar shoe ${position}`,
        productName
      );
      this.sendGaEvent('GuideCompareProductClicked', 'GuideSectionCompare', {
        compare_product: productName,
        compare_position: position,
        guide: this.guideName(),
      });
    },
    guideSimilarProductRemoved(productName, position) {
      this.sendEvent(
        'GuideSectionCompare',
        `ProductRemoved ${position}`,
        productName
      );
      this.sendGaEvent('GuideCompareProductRemoved', 'GuideSectionCompare', {
        compare_product: productName,
        compare_position: position,
        guide: this.guideName(),
      });
    },
    guideSimilarSearchClicked(location = 'Guide') {
      this.sendEvent(
        'GuideSectionCompare',
        `SearchClicked - ${location}`,
        this.guideName(),
      );
      this.sendGaEvent('GuideCompareSearchClicked', 'GuideSectionCompare', {
        compare_search_location: location,
        guide: this.guideName(),
      });
    },
    guideSimilarSameBrandClicked() {
      this.sendEvent(
        'GuideSectionCompare',
        `SameBrandClicked`,
        this.guideName(),
      );
      this.sendGaEvent('GuideCompareSameBrandClicked', 'GuideSectionCompare', {
        guide: this.guideName(),
        ...this.productParams(),
      });
    },
    productSponsoredReviewLinkClicked(expertName, siteName, position) {
      this.sendEvent(
        'SectionExperts',
        `Sponsored Expert ${position} - ${expertName}`,
        this.productName(),
      );
      this.sendGaEvent('ExpertSponsoredClicked', 'SectionExperts', {
        expert: expertName,
        expert_position: position,
        ...this.productParams(),
      });
      this.productExpertLinkClicked(siteName);
    },
    productExpertReviewLinkClicked(expertName, siteName, position) {
      this.sendEvent('SectionExperts', `Expert ${position} - ${expertName}`, this.productName());
      this.sendGaEvent('ExpertReviewClicked', 'SectionExperts', {
        expert: expertName,
        expert_position: position,
        ...this.productParams(),
      });
      this.productExpertLinkClicked(siteName);
    },
    productExpertLinkClicked(siteName) {
      const productName = this.productName();

      this.sendEvent('outboundClicksExperts', 'click', `OLEshoe - ${productName}`);

      if (siteName) {
        this.sendEvent('outboundClicksExperts', 'click', `OLE - ${siteName}`);
      }

      this.sendGaEvent('OutboundExpertClick', 'OutboundExperts', {
        site_name: siteName,
        ...this.productParams(),
      });
    },
    productApplyExpertClicked() {
      this.sendEvent('SectionExperts', 'Apply', this.productName());
      this.sendGaEvent('ExpertApplied', 'SectionExperts', ...this.productParams());
    },
    rankingViewChanged(viewType) {
      this.sendEvent('RankingPage', 'ViewType', viewType);
    },
    rankingOrderChanged(orderType) {
      this.sendEvent('RankingPage', 'OrderBy', orderType);
      this.sendGaEvent('CatalogOrderBy', 'CatalogPage', {
        ranking_order: orderType,
      });
    },
    rankingMobileFilterOpened(buttonName) {
      this.sendEvent('RankingPage', 'MobileFilter', buttonName);
      this.sendGaEvent('CatalogMobileFilter', 'CatalogPage', {
        ranking_button: buttonName,
      });
    },
    rankingFilterChanged(filterName, conditionSlug, source = 'main') {
      this.sendEvent(
        'RankFilter',
        (source === 'main' ? '' : source + '-') + filterName,
        conditionSlug,
      );
      this.sendGaEvent('CatalogFilterChanged', 'CatalogFilter', {
        filter_name: filterName,
        filter_source: source,
        filter_value: conditionSlug,
      });
    },
    rankingFilterNoSizeChanged(location, filterName) {
      this.sendEvent('CatalogFilterNoSize', location, filterName);
      this.sendGaEvent('CatalogFilterNoSize', 'CatalogPage', {
        no_size_location: location,
        no_size_filter: filterName,
      });
    },
    rankingListPositionClicked(position, productName) {
      this.sendEvent('RankingListPositionClicked', 'Pos' + position.toString(), productName);
      this.sendGaEvent('CatalogProductClicked', 'CatalogPage', {
        product: productName,
        product_position: position,
      });
    },
    rankingListAffiliateLinkClicked(offer, productName) {
      this.sendPriceEvent(offer.shop_id, '', 'RankingRetailers', productName);
      this.sendEvent(
        'WidthOfferClicked',
        main.gender.toUpperCase() + '-' + main.width,
        productName,
      );

      this.sendOfferClicked('CatalogPageOfferClicked', offer, offer.product_id, productName, offer.is_any_color);
    },
    rankingDealAffiliateLinkClicked(offer, productName) {
      this.sendPriceEvent(offer.shop_id, '', 'DealsRetailers', productName);
      this.sendEvent('SectionRankingDeals', 'dealLink', productName);
      this.sendGaEvent('DealClicked', 'SectionCatalogDeals', {
        product: productName,
      });
      this.sendOfferClicked('DealsPageOfferClicked', offer, offer.product_id, productName, offer.is_any_color);
    },
    buyingGuideBestOverallAffiliateLinkClicked(offer, productName, isAnyCOlor) {
      this.sendPriceEvent(offer.shop_id, '', 'GuideRetailers', productName);
      this.sendEvent('SectionGuideBest', 'dealLink', productName);
      this.sendGaEvent('DealClicked', 'SectionGuideBest', {
        product: productName,
      });
      this.sendOfferClicked('GuidePageOfferClicked', offer, offer.product_id, productName, isAnyCOlor);
    },
    buyingGuideContentAffiliateLinkClicked(offer, productName, isAnyColor) {
      this.sendPriceEvent(offer.shop_id, '', 'GuideRetailers', productName);
      this.sendEvent('SectionGuideContent', 'dealLink', productName);
      this.sendGaEvent('DealClicked', 'SectionGuideContent', {
        product: productName,
      });
      this.sendOfferClicked('GuidePageOfferClicked', offer, offer.product_id, productName, isAnyColor);
    },
    buyingGuideBestOverallProductLinkClicked(label) {
      this.sendEvent('SectionGuideBest', 'ProductLink', label);
      this.sendGaEvent('GuideProductClicked', 'SectionGuideBest', {
        product: label,
      });
    },
    buyingGuideBestOverallCatalogLinkClicked(label) {
      this.sendEvent('SectionGuideBest', 'CatalogLink', label);
      this.sendGaEvent('GuideCatalogClicked', 'SectionGuideBest', {
        catalog: label,
      });
    },
    buyingGuideBestImageSelected(position, productName) {
      this.sendEvent('SectionGuideBest', `ImageSelected - ${position}`, productName);
      this.sendGaEvent('GuideProductImageSelected', 'SectionGuideBest', {
        position: position,
        product: productName,
      });
    },
    buyingGuideContentProductLinkClicked(label) {
      this.sendEvent('SectionGuideContent', 'ProductLink', label);
      this.sendGaEvent('GuideProductClicked', 'SectionGuideContent', {
        product: label,
      });
    },
    buyingGuideContentReviewLinkClicked(label) {
      this.sendEvent('SectionGuideContent', 'ReviewLink', label);
      this.sendGaEvent('GuideReviewClicked', 'SectionGuideContent', {
        guide_review: label,
      });
    },
    surveyShown(surveyType) {
      // this.sendEvent('Surveys', 'SurveyShown', surveyType);
      // this.sendGaEvent('SurveyShown', 'Surveys', {});
    },
    surveySubmitted(surveyType) {
      this.sendEvent('Surveys', 'SurveySubmitted', surveyType);
      this.sendGaEvent('SurveySubmitted', 'Surveys', {
        survey_type: surveyType,
      });
    },
    navigationGoToTopClicked() {
      this.sendEvent('Navigation', 'GoToTop', page.type);
      this.sendGaEvent('NavigationGoToTop', 'Navigation', {
        navigation_page_type: page.type,
      });
    },
    tableOfContentClicked(page, slug, section) {
      const pageCapitalized = page.charAt(0).toUpperCase() + page.slice(1).toLowerCase();
      this.sendEvent('TocSection', `Toc${pageCapitalized}Clicked`, slug);
      this.sendGaEvent(`TocLinkClicked`, 'TocSection', {
        toc_page: page,
        toc_slug: slug,
        toc_section: section.id,
      });

      this.sendGaEvent(`Toc${pageCapitalized}Clicked`, 'TocSection', {
        toc_page_slug: slug,
        toc_section: section.id,
      });
    },
    pageTabChanged(pageType, tabId, pageSlug) {
      this.sendEvent('PageTab', pageType+'-'+tabId, pageSlug);
      this.sendGaEvent('PageTabChanged', pageType, {
        tab: tabId,
        slug: pageSlug,
      });
    },
  }}};
});
