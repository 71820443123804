<template>
  <div class="black-friday">
    <a :href="link" class="container">
      <PortugalIcon v-if="countryIsPortugal" />
      <SpeakerIcon v-else />
      {{ text }}
      <AngleDoubleRightIcon fill="#000" class="black-friday__icon" />
    </a>
  </div>
</template>

<script>
import AngleDoubleRightIcon from '~/components/icons/awesome-font/AngleDoubleRightIcon';
import SpeakerIcon from '~/components/icons/SpeakerIcon.vue';
import PortugalIcon from '~/components/icons/PortugalIcon.vue';
import { mapState } from "pinia";
import { useMainStore } from "~/stores/main";
import { useCategoryGroupsStore } from "~/stores/categoryGroups";

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
  components: {
    AngleDoubleRightIcon,
    SpeakerIcon,
    PortugalIcon,
  },
  computed: {
    ...mapState(useMainStore, ['countryIsPortugal']),
    ...mapState(useCategoryGroupsStore, ['blackFridayLink']),
  },
};
</script>

<style lang="scss" scoped>
.black-friday {
  display: flex;
  padding: 15px;
  font-size: 1.8rem;
  // font-weight: normal;
  line-height: 17px;
  height: 47px;
  background: $yellow;
  // text-align: center;
  &:hover {
    text-decoration: none;
  }

  a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #fff;
    @media (max-width: $break_xs_under) {
      padding: 0;
    }
  }
  .black-friday__icon {
    top: 0;
    margin-left: 5px;
  }
}
</style>
