<template>
  <ThePage class="container">
    <div class="content">
      <div class="title">{{ $t('not_found.title') }}</div>
      <div class="quote" v-if="error.statusCode === 404">{{ $t('not_found.description') }}</div>
      <div class="quote" v-else>{{ $t('not_found.something_wrong') }}</div>
      <div v-if="isAdmin">
        <h2 class="text-danger" v-text="error.message" />
        <h3 v-text="error.url" />
        <div v-html="error.stack"></div>
      </div>
      <div v-else class="categories">
        <h3>{{ $t('generic.categories') }}</h3>
        <ul class="categories-list">
          <li>
            <a
              :title="$t('pages.categories.running')"
              :href="localePath({name: 'catalog-slug', params: {slug: $t('url.running_shoes')}})"
            >&gt; {{ $t('pages.categories.running') }}</a>
          </li>
          <li>
            <a
              :title="$t('pages.categories.sneakers')"
              :href="localePath({name: 'catalog-slug', params: {slug: $t('url.sneakers')}})"
            >&gt; {{ $t('pages.categories.sneakers') }}</a>
          </li>
          <li>
            <a
              :title="$t('pages.categories.hiking')"
              :href="localePath({name: 'catalog-slug', params: {slug: $t('url.hiking_footwear')}})"
            >&gt; {{ $t('pages.categories.hiking') }}</a>
          </li>
          <li>
            <a
              :title="$t('pages.categories.training')"
              :href="localePath({name: 'catalog-slug', params: {slug: $t('url.training_shoes')}})"
            >&gt; {{ $t('pages.categories.training') }}</a>
          </li>
          <li>
            <a
              :title="$t('pages.categories.basketball')"
              :href="localePath({name: 'catalog-slug', params: {slug: $t('url.basketball_shoes')}})"
            >&gt; {{ $t('pages.categories.basketball') }}</a>
          </li>
        </ul>
      </div>
      <div class="search-shoe">
        <p>{{ $t('not_found.search') }}</p>
        <div
          class="rr-shoes-search-input"
          data-search=""
          data-expandable="false"
          data-search-icon-position="left"
        >
          <form class="header_search" data-header="search" @submit.prevent.stop="submit">
            <div>
              <input
                id="search_input"
                v-model="searchTerm"
                autocomplete="off"
                name="q"
                :placeholder="$t('not_found.search_placeholder')"
                type="text"
              />
              <div class="clear"></div>
            </div>
          </form>
          <button class="buy_now_button" @click.prevent.stop="submit">
            {{ $t('generic.search') }}
          </button>
        </div>
      </div>
    </div>
  </ThePage>
</template>

<script setup>
import SearchIcon from '@/components/icons/awesome-font/SearchIcon';
import { useMainStore } from "~/stores/main";

const layout = 'empty';

const props = defineProps({
  error: Object
})

const main = useMainStore();
const isAdmin = ref(!!useCookie('admin_country').value);
const searchTerm = ref('');

function submit() {
  if (!searchTerm) {
    return false;
  }

  navigateTo(`/search?q=${searchTerm.value}`);
}
</script>
<style scoped lang="scss">
.container {
  text-align: center;
  padding: 100px 0;
}

.container .content {
  text-align: left;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.container .title {
  font-size: 120px;
  font-weight: 400;
  line-height: initial;
  color: #484848;
}

.container .quote {
  font-size: 30px;
  padding-top: 16px;
}

.container .categories {
  padding-top: 17px;
}

.container .categories h3 {
  font-size: 30px;
}

.container ul.categories-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none;
  padding-left: 0;
  max-width: 220px;
}

.container .search-shoe {
  padding-top: 10px;
}

.container .rr-shoes-search-input form {
  float: none;
  margin: 0;
  width: 75%;
  background: 0 0;
  width: calc(100% - 140px);
  padding-left: 0;
  display: inline-block !important;
  transition: none !important;
  height: 44px;
}

@media (max-width: 767px) {
  .container .rr-shoes-search-input form {
    position: static;
    top: 0;
    right: 0;
    position: relative;
  }
}

.container .rr-shoes-search-input form input[type='text'] {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 100%;
  // width: calc(100% - 36px);
}

.container .rr-shoes-search-input form button {
  float: left;
}

.container .rr-shoes-search-input form button .search_icon {
  color: #ccc;
  font-size: 18px;
}

.container .rr-shoes-search-input .buy_now_button {
  vertical-align: top;
  margin-left: 15px;
  margin-top: 0;
  line-height: 41px;
  padding: 0 22px;
}

.container .rr-shoes-search-input #search_suggestions {
  top: auto !important;
  bottom: 100% !important;
  width: 150%;
}

@media (max-width: 767px) {
  .container .rr-shoes-search-input #search_suggestions {
    top: auto !important;
    bottom: 100% !important;
  }
}

.rr-shoes-search-input {
  .header_search {
    float: right;
    margin: 11px 0 7px 15px;
    padding: 0;
    width: 25%;
    height: 36px;
    background: $dark-blue;
    border-radius: 2px;
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;

    @media (max-width: $break_lg_under) and (min-width: $break_md_upper) {
      width: 12%;
    }
    @media (max-width: $break_sm_under) {
      display: none;
      float: none;
      position: absolute;
      margin-top: 0;
      top: -3px;
      right: 100px;
      width: 100%;
    }
    @media (min-width: $break_sm_upper) {
      padding-left: 23px;
    }
    @media (max-width: $break_xs_under) {
      right: 0;
      top: -6px;
    }
    button {
      background-color: #fff;
      border: none;
      display: block;
      float: right;
      padding: 0 5px;
      text-align: right;
      font-size: 0.9em;
      color: $dark-blue;
      height: 100%;
      .search_icon {
        height: 30px;
        margin-top: 0px;
        line-height: 30px;
        display: block;
        color: $dark-blue;
        background-color: #fff;
        padding: 0;
        font-size: 0.8em;
      }
    }
    input[type='text'] {
      height: 34px;
      border: none;
      font-size: 1em;
      padding: 0 5px;
      display: block;
      float: left;
      background: #fff;
      outline: 0;
      width: 83%;
      @media (max-width: $break_lg_under) and (min-width: $break_md_upper) {
        width: 70%;
      }
    }
    input[type='text']:focus {
      outline: 0;
      // width: 400px;
    }
    & > div {
      border: 1px solid #ccc;
      border-radius: 2px;
      width: 100%;
      height: 100%;
      .clear {
        background: #fff;
      }
    }

    &.expanded {
      position: absolute;
      z-index: 10000;
      @media (min-width: $break_xs_upper) {
        width: 40%;
        right: 15px;
        padding-left: 0px;
      }
    }

    #search_suggestions {
      position: absolute;
      top: 36px;
      left: 0;
      width: 100%;
      height: auto;
      border: 1px solid #ccc;
      background: #fff;
      list-style: none;
      margin: 0;
      padding: 0;
      z-index: 10000;
      li {
        list-style: none;
        margin: 0;
        padding: 0;
        border-bottom: 1px dotted #ccc;
        a {
          position: relative;
          display: flex;
          align-items: center;
          color: #333;
          background-color: #fff;
          padding: 0px 15px 0px 15px;
          //white-space: nowrap;
          overflow: hidden;
          //text-overflow: ellipsis;
          border: 1px solid #fff;
          height: 54px;
          &.all-results {
            padding-left: 15px;
          }
          &.selected-suggestion {
            border-color: $green;
          }
          span.ss_image {
            img {
              /*position: absolute;
            left: 15px;
            top:50%;
            -webkit-transform: translateY( -50% );
            -moz-transform: translateY( -50% );
            -ms-transform: translateY( -50% );
            -o-transform: translateY( -50% );
            transform: translateY( -50% );*/
            }
          }
          span.ss_title {
            padding-left: 15px;
          }
        }
        &.all-results,
        &.search-history-item {
          a {
            padding-left: 15px;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .search_background {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: -1;
      height: 100%;
      width: 100%;
      background-color: #1a3338;
      opacity: 0.4;
    }
  }
  .search_background {
    //display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    background-color: #1a3338;
    opacity: 0.4;
  }
}
</style>
