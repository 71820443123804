<template>
  <div class="categories-navbar navbar-collapse navbar-right">
    <ul id="menu-main" class="nav navbar-nav">
      <li
        v-for="(element, index) in navMenu"
        :key="index"
        class="menu-item menu-item-type-custom menu-item-object-custom"
        @mouseenter.prevent="
          $device.isMobileOrTablet ? null : showSecondLevelMenu(element.name.toLowerCase())
        "
        @mouseleave.prevent="
          $device.isMobileOrTablet ? null : hideSecondLevelMenu(element.name.toLowerCase(), true)
        "
      >
        <a
          v-if="element.url"
          class="menu-item-link"
          :class="{ 'menu-no-items': !element.items, 'top-level-drop-down': element.items }"
          :href="element.url"
          >{{ element.name }}
        </a>
        <button
          v-else
          type="button"
          class="top-level-drop-down top-level-reviews"
          data-toggle="dropdown"
          :title="element.name"
          :class="{ open: showMenus[element.name.toLowerCase() + 'Menu'] }"
          @click.prevent.stop="toggleSecondLevelMenu(element.name.toLowerCase())"
        >
          {{ element.name }}
          <span class="next-arrow" />
        </button>
        <div
          v-if="element.items"
          v-show="showMenus[element.name.toLowerCase() + 'Menu']"
          class="second-level-drop-down second-level-drop-down-reviews"
          :class="{
            'three-columns': elementToDisplay(element.items).length === 3,
            'two-columns': elementToDisplay(element.items).length === 2,
          }"
          @mouseenter="cancelHidingMenu"
        >
          <ul>
            <li v-for="(item, index) in elementToDisplay(element.items)" :key="index">
              <h3 v-if="item.name" class="second-level-drop-down-links">
                {{ item.name }}<span class="next-arrow" />
              </h3>
              <ul v-if="item.items.length > 0" class="third-level-drop-down">
                <li
                  v-if="index == 0"
                  class="back-action-container"
                  @click.prevent.stop="hideSecondLevelMenu(element.name.toLowerCase())"
                >
                  <button type="button" class="back-action second-level-drop-down-back-action">
                    <span class="previous-arrow" />
                    {{ $t('generic.back') }}
                  </button>
                </li>

                <li
                  v-for="(menuItem, index) in item.items"
                  :key="index"
                  @click="$emit('categoryLinkClicked', { url: menuItem.url })"
                >
                  <a :href="menuItem.url" @click="hideSecondLevelMenu(element.name.toLowerCase())">
                    <CategoryIcon
                      v-if="menuItem.slug"
                      :slug="menuItem.slug"
                      style="max-width: 30px; max-height: 30px"
                    />
                    {{ menuItem.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { usePageStore } from '~/stores/page';
import { useCategoryGroupsStore } from '~/stores/categoryGroups';
const CategoryIcon = defineAsyncComponent(() => import('~/components/icons/categories/CategoryIcon'));

export default {
  components: {
    CategoryIcon,
  },
  emits: ['categoryLinkClicked'],
  data() {
    return {
      collapseIn: false,
      dealsMenu: false,
      reviewsMenu: false,
      timerId: null,
      showMenus: {},
    };
  },
  computed: {
    ...mapState(usePageStore, {
      navMenu: store => store.global.nav_menu,
      showDeals: store => store.global.show_deals,
    }),
    ...mapState(useCategoryGroupsStore, ['blackFridayLink']),
  },
  methods: {
    elementToDisplay(items) {
      const cntBase = Math.floor(items.length / 2);
      const cnt = items.length % 2 === 0 ? cntBase : cntBase + 1;

      return [
        {
          items: items.slice(0, cnt),
        },
        {
          items: items.slice(cnt),
        },
      ];
    },
    getArray(items) {
      return typeof items === 'object' ? Object.values(items) : items;
    },
    showSecondLevelMenu(type) {
      // If second type is opened, close immediatelly.
      if (this.timerId) {
        this.cancelHidingMenu();
        const openMenu = Object.keys(this.showMenus).find(key => this.showMenus[key]);
        if (openMenu) {
          this.hideSecondLevelMenu(openMenu, false, true);
        }
      }
      this.showMenus[`${type}Menu`] = true;
    },
    hideSecondLevelMenu(type, timeout = false, fullName = false) {
      const menuName = fullName ? type : `${type}Menu`;
      if (timeout) {
        const vm = this;
        this.timerId = setTimeout(() => {
          this.showMenus[menuName] = false;
        }, 150);
      } else {
        this.showMenus[menuName] = false;
      }
    },
    toggleSecondLevelMenu(type) {
      if (this[`${type}Menu`]) {
        this.hideSecondLevelMenu(type);
      } else {
        this.showSecondLevelMenu(type);
      }
    },
    cancelHidingMenu() {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
    },
    secondLevelDropDownOpened(type) {
      return this.showMenus[`${type}Menu`];
    },
  },
};
</script>

<style lang="scss" scoped>
$break_sm_under_ipad: $break_md_under;
$break_sm_upper_ipad: $break_md_upper;

.categories-navbar {
  @media (max-width: $break_sm_under_ipad) {
    overflow: visible;
    visibility: visible;
  }
  &.collapsing {
    overflow: hidden;
  }
  @media (min-width: $break_sm_upper_ipad) {
    & > ul {
      padding-top: 5px;
    }
  }
  @media (max-width: $break_sm_under_ipad) {
    max-height: calc(100vh - 60px);
    border-bottom: 1px solid #ccc;
    overflow-y: scroll !important;
  }
  ul {
    list-style: none;
    @media (min-width: $break_sm_upper_ipad) {
      .top-level-drop-down {
        &:not(.has-no-items) {
          &:hover,
          &.open {
            &:before {
              content: '';
              position: absolute;
              top: calc(100% + 5px);
              z-index: 2;
              left: 50%;
              transform: translateX(-50%);
              height: 4px;
              width: 4px;
              display: inline-block;
              margin-left: 2px;
              vertical-align: middle;
              border-top: 4px solid;
              border-right: 4px solid transparent;
              border-left: 4px solid transparent;
              color: $orange;
            }
          }
        }

        &:hover,
        &.open {
          position: relative;
          border-bottom: 5px solid $orange !important;
        }
      }
      li {
        &:last-of-type {
          .second-level-drop-down {
            right: 0 !important;
          }
        }
      }
      .second-level-drop-down {
        //display: none;
        position: absolute;
        float: left;
        background: #fff;
        color: #000;
        &.three-columns {
          min-width: 600px;
        }
        &.two-columns {
          min-width: 400px;
        }
        padding: 30px;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.21);
        right: -196px;
        & > ul {
          margin-left: 0;
          padding-left: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          & > li {
            margin: 0 !important;
          }
        }
        h3 {
          font-weight: bold;
          font-size: 16px;
          padding-bottom: 2px;
          padding-top: 0;
          margin-top: 0;
        }
        .third-level-drop-down {
          display: block;
          padding: 0 !important;
          margin-top: -15px;
          li {
            display: block;
            margin: 0;
            padding-top: 15px;
            white-space: nowrap;
            min-width: 120px;
            position: relative;
            padding-left: 38px;
            &.back-action-container {
              display: none;
            }
            a {
              color: #000 !important;
              padding: 0;
              font-size: 16px;
              &.nuxt-link-active,
              &:hover {
                text-decoration: underline;
                border-bottom: none;
              }
              svg {
                position: absolute;
                left: 0;
                top: 12px;
              }
            }
          }
        }
      }
      li {
        display: inline-block;
        vertical-align: top;
        &.back-action-container {
          display: none;
        }
        .second-level-drop-down {
          background-color: #fff;
          & > a {
            color: #000 !important;
          }
        }
      }
      & > li:last-of-type {
        margin-right: 0 !important;
      }
    }
    @media (max-width: $break_sm_under_ipad) {
      padding: 0 !important;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.21);
      float: none !important;
      .second-level-drop-down {
        h3.second-level-drop-down-links {
          display: none;
          box-shadow: none;
        }
        .third-level-drop-down {
          //display: none;
          box-shadow: none;
          li {
            a {
              position: relative;
              padding-left: 53px !important;
              svg {
                position: absolute;
                left: 15px;
                top: 12px;
              }
            }
          }
        }
      }
      li {
        margin: 0 !important;
        padding: 0 !important;
        float: none !important;
        a.nuxt-link-active {
          font-weight: bold;
        }
        button,
        a,
        h3 {
          display: inline-block;
          height: 50px;
          line-height: 50px;
          text-align: left;
          font-size: 14px !important;
          width: 100%;
          padding: 0 20px !important;
          border-bottom: 1px solid $border-color-grey !important;
          font-size: 16px;
          position: relative;
          margin: 0;
          color: #000 !important;
          &:last-of-type {
            border-bottom: none;
          }
          &.back-action {
            color: #000;
            background-color: $grey-normal !important;
            padding-left: 38px !important;
            @media (min-width: $break_sm_upper_ipad) {
              display: none;
            }
          }
          .previous-arrow,
          .next-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            height: 13px;
            width: 8px;
          }
          .previous-arrow {
            left: 18px;
            @include icon-svg-arrow-left('#000', 8, 13);
          }
          .next-arrow {
            right: 17px;
            @include icon-svg-arrow-right('#000', 8, 13);
          }
        }
      }

      button.top-level-drop-down.open {
        display: none;
      }
    }
    li.categories-navbar__black-friday {
      // background-color: $yellow;
      padding: 5px;
      position: relative;
      top: 10px;
      border-radius: 2px;
      a {
        padding: 0;
        // color: #fff !important;
      }
      @media (max-width: $break_md_under) {
        top: 0;
      }
    }
  }
  .menu-item-link {
    color: #ffffff;
  }
}

//Menu
.navbar-collapse {
  padding-right: 0;
  z-index: 1000;
  .navbar-nav {
    padding-top: 8px;
    .active {
      background-color: $dark-blue;
    }
  }
  @media (max-width: $break_sm_under_ipad) {
    top: 45px;
    position: absolute;
    right: -30px;
    margin: 0;
    padding: 0;
    min-width: 240px;
    width: calc(100vw + 15px);
    background-color: $background-grey-light;
    .navbar-nav {
      margin: 0;
      .active {
        background-color: transparent;
      }
    }
  }
  @media (min-width: $break_sm_under_ipad) and (max-width: $break_sm_under_ipad) {
    top: $top_header_offset !important;
    right: -9px !important;
  }
  @media (min-width: 768px) and (max-width: $break_md_upper) {
    right: calc(-1 * (100vw - 750px) / 2);
    top: $top_header_offset !important;
  }
  ul {
    > li {
      padding: 0;
      margin: 0 15px;
      background-color: transparent;
      @media (max-width: $break_md_under) {
        margin: 0 5px;
      }
      @media (max-width: $break_sm_under_ipad) {
        padding: 0;
        margin: 10px;
        background-color: transparent;
      }
      > a,
      button {
        margin: 0;
        padding: 15px 0;
        border-bottom: 5px solid transparent;
        color: #fff;
        background-color: transparent;
        border: 0;
        font-size: 1em;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        text-decoration: none;
        letter-spacing: 1px;
        @media (max-width: $break_sm_under_ipad) {
          padding: 5px 10px;
          font-size: 14px !important;
        }
      }
      &.active {
        > a,
        button {
          border-bottom: 5px solid $orange-background;
          background-color: transparent;
        }
      }
      > button:hover {
        border-bottom: 5px solid $orange-background;
        color: #fff;
        background-color: transparent;
        @media (max-width: $break_sm_under_ipad) {
          border-bottom: 5px solid transparent;
        }
      }
    }
  }
}
</style>