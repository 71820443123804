export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      filters: {
        formatSize(size, system) {
          if (!size) {
            return '';
          }
          if (system && system == 'mm') return size / 10;
          return size.toString().replace(
            /(\.125)|(\.25)|(\.333)|(\.375)|(\.5)|(\.625)|(\.667)|(\.75)|(\.875)/g,
            function(str, p1, p2, p3, p4, p5, p6, p7, p8, p9) {
              if (p1) return '&frac18;';
              if (p2) return '&frac14;';
              if (p3) return '&frac13;';
              if (p4) return '&frac38;';
              if (p5) return '&frac12;';
              if (p6) return '&frac58;';
              if (p7) return '&frac23;';
              if (p8) return '&frac34;';
              if (p9) return '&frac78;';
            }
          );
        },
        capitalize(value) {
          if (!value) return '';
          value = value.toString();
          return value.charAt(0).toUpperCase() + value.slice(1);
        },

        /**
         * Vue filter to round the decimal to the given place.
         * http://jsfiddle.net/bryan_k/3ova17y9/
         *
         * @param {String} value    The value string.
         * @param {Number} decimals The number of decimal places.
         */
        roundDecimal(value, decimals) {
          if (!value) {
            value = 0;
          }

          if (!decimals) {
            decimals = 0;
          }
          return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
        },

        /**
         * Format number with commas
         * https://stackoverflow.com/a/2901298
         * @param x
         */
        numberWithCommas(value, separator = ',') {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
        },

        formatPrice(price, currency, cent = false) {
          if (cent) {
            price = Math.round(parseFloat(price) / 100).toString();
          }
          return currency && (currency.length === 1 || currency.slice(-1) === '$')
            ? currency + price
            : `${price}<sup>${currency}</sup>`;
        },

        round(value) {
          return Number.isNaN(value) ? value : Math.round(value);
        },

        kFormatter(num) {
          return Math.abs(num) > 999
            ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  }
});
