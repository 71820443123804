import { default as _91expert_93nMYGqRT6K7Meta } from "/builds/RunRepeat/rr_front/pages/[slug]/expert/[expert].vue?macro=true";
import { default as indexGal9WfwMcFMeta } from "/builds/RunRepeat/rr_front/pages/[slug]/index.vue?macro=true";
import { default as _91slug_93YyXS9QIA2LMeta } from "/builds/RunRepeat/rr_front/pages/author/[slug].vue?macro=true";
import { default as indextuE0CjWtylMeta } from "/builds/RunRepeat/rr_front/pages/author/index.vue?macro=true";
import { default as become_45an_45expert_45formSvW15DB7LDMeta } from "/builds/RunRepeat/rr_front/pages/become-an-expert-form.vue?macro=true";
import { default as runner_45performanceg4aRLENCDhMeta } from "/builds/RunRepeat/rr_front/pages/calculator/runner-performance.vue?macro=true";
import { default as _91slug_93U7JwhJQgacMeta } from "/builds/RunRepeat/rr_front/pages/catalog/[slug].vue?macro=true";
import { default as indexhkcgDQdIFNMeta } from "/builds/RunRepeat/rr_front/pages/catalog/index.vue?macro=true";
import { default as _91slug_93v878FNHRGfMeta } from "/builds/RunRepeat/rr_front/pages/deals/[slug].vue?macro=true";
import { default as index1I0237UciqMeta } from "/builds/RunRepeat/rr_front/pages/deals/index.vue?macro=true";
import { default as _91slug_935GWQSaE3OrMeta } from "/builds/RunRepeat/rr_front/pages/guides/[slug].vue?macro=true";
import { default as indexAtGiCSzeGIMeta } from "/builds/RunRepeat/rr_front/pages/guides/index.vue?macro=true";
import { default as index0MiQUmEHjIMeta } from "/builds/RunRepeat/rr_front/pages/index.vue?macro=true";
import { default as _91topic_93EQww0GPVhsMeta } from "/builds/RunRepeat/rr_front/pages/news/[topic].vue?macro=true";
import { default as indexleQOezv4vpMeta } from "/builds/RunRepeat/rr_front/pages/news/index.vue?macro=true";
import { default as _91id_93aCDHPdFms7Meta } from "/builds/RunRepeat/rr_front/pages/preview/[type]/[id].vue?macro=true";
import { default as _91slug_932dP2aAgvRKMeta } from "/builds/RunRepeat/rr_front/pages/redirect/[slug].vue?macro=true";
import { default as searchYRATwHrAjWMeta } from "/builds/RunRepeat/rr_front/pages/search.vue?macro=true";
import { default as _91slug_93rhfJX2y4xYMeta } from "/builds/RunRepeat/rr_front/pages/sitemap/[slug].vue?macro=true";
import { default as indexuQrPAayuDJMeta } from "/builds/RunRepeat/rr_front/pages/sitemap/index.vue?macro=true";
import { default as _91slug_93ramq7V5yIeMeta } from "/builds/RunRepeat/rr_front/pages/size-charts/[slug].vue?macro=true";
import { default as indexAdrAmKcBkhMeta } from "/builds/RunRepeat/rr_front/pages/size-charts/index.vue?macro=true";
import { default as unsubscribeYUSWvQeAVjMeta } from "/builds/RunRepeat/rr_front/pages/unsubscribe.vue?macro=true";
export default [
  {
    name: "slug-expert-expert___es",
    path: "/es/:slug()/expert/:expert()",
    component: () => import("/builds/RunRepeat/rr_front/pages/[slug]/expert/[expert].vue").then(m => m.default || m)
  },
  {
    name: "slug-expert-expert___uk",
    path: "/uk/:slug()/expert/:expert()",
    component: () => import("/builds/RunRepeat/rr_front/pages/[slug]/expert/[expert].vue").then(m => m.default || m)
  },
  {
    name: "slug-expert-expert___en",
    path: "/:slug()/expert/:expert()",
    component: () => import("/builds/RunRepeat/rr_front/pages/[slug]/expert/[expert].vue").then(m => m.default || m)
  },
  {
    name: "slug___es",
    path: "/es/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___uk",
    path: "/uk/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "author-slug___es",
    path: "/es/autor/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___uk",
    path: "/uk/author/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___en",
    path: "/author/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author___es",
    path: "/es/autor",
    component: () => import("/builds/RunRepeat/rr_front/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "author___uk",
    path: "/uk/author",
    component: () => import("/builds/RunRepeat/rr_front/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "author___en",
    path: "/author",
    component: () => import("/builds/RunRepeat/rr_front/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: "become-an-expert-form",
    path: "/become-an-expert-form",
    component: () => import("/builds/RunRepeat/rr_front/pages/become-an-expert-form.vue").then(m => m.default || m)
  },
  {
    name: "calculator-runner-performance",
    path: "/calculator/runner-performance",
    meta: runner_45performanceg4aRLENCDhMeta || {},
    component: () => import("/builds/RunRepeat/rr_front/pages/calculator/runner-performance.vue").then(m => m.default || m)
  },
  {
    name: "catalog-slug___es",
    path: "/es/catalogo/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/catalog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "catalog-slug___uk",
    path: "/uk/catalog/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/catalog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "catalog-slug___en",
    path: "/catalog/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/catalog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "catalog___es",
    path: "/es/catalogo",
    component: () => import("/builds/RunRepeat/rr_front/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog___uk",
    path: "/uk/catalog",
    component: () => import("/builds/RunRepeat/rr_front/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog___en",
    path: "/catalog",
    component: () => import("/builds/RunRepeat/rr_front/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "deals-slug___es",
    path: "/es/ofertas/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/deals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "deals-slug___uk",
    path: "/uk/deals/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/deals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "deals-slug___en",
    path: "/deals/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/deals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "deals___es",
    path: "/es/ofertas",
    component: () => import("/builds/RunRepeat/rr_front/pages/deals/index.vue").then(m => m.default || m)
  },
  {
    name: "deals___uk",
    path: "/uk/deals",
    component: () => import("/builds/RunRepeat/rr_front/pages/deals/index.vue").then(m => m.default || m)
  },
  {
    name: "deals___en",
    path: "/deals",
    component: () => import("/builds/RunRepeat/rr_front/pages/deals/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-slug___es",
    path: "/es/guias/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: "guides-slug___uk",
    path: "/uk/guides/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: "guides-slug___en",
    path: "/guides/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: "guides___es",
    path: "/es/guias",
    component: () => import("/builds/RunRepeat/rr_front/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "guides___uk",
    path: "/uk/guides",
    component: () => import("/builds/RunRepeat/rr_front/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "guides___en",
    path: "/guides",
    component: () => import("/builds/RunRepeat/rr_front/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/builds/RunRepeat/rr_front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___uk",
    path: "/uk",
    component: () => import("/builds/RunRepeat/rr_front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/builds/RunRepeat/rr_front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-topic___es",
    path: "/es/noticias/:topic()",
    component: () => import("/builds/RunRepeat/rr_front/pages/news/[topic].vue").then(m => m.default || m)
  },
  {
    name: "news-topic___uk",
    path: "/uk/news/:topic()",
    component: () => import("/builds/RunRepeat/rr_front/pages/news/[topic].vue").then(m => m.default || m)
  },
  {
    name: "news-topic___en",
    path: "/news/:topic()",
    component: () => import("/builds/RunRepeat/rr_front/pages/news/[topic].vue").then(m => m.default || m)
  },
  {
    name: "news___es",
    path: "/es/noticias",
    component: () => import("/builds/RunRepeat/rr_front/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___uk",
    path: "/uk/news",
    component: () => import("/builds/RunRepeat/rr_front/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/news",
    component: () => import("/builds/RunRepeat/rr_front/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "preview-type-id",
    path: "/preview/:type()/:id()",
    component: () => import("/builds/RunRepeat/rr_front/pages/preview/[type]/[id].vue").then(m => m.default || m)
  },
  {
    name: "redirect-slug___es",
    path: "/es/redireccionar/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/redirect/[slug].vue").then(m => m.default || m)
  },
  {
    name: "redirect-slug___uk",
    path: "/uk/redirect/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/redirect/[slug].vue").then(m => m.default || m)
  },
  {
    name: "redirect-slug___en",
    path: "/redirect/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/redirect/[slug].vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/busqueda",
    component: () => import("/builds/RunRepeat/rr_front/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___uk",
    path: "/uk/search",
    component: () => import("/builds/RunRepeat/rr_front/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/builds/RunRepeat/rr_front/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "sitemap-slug___es",
    path: "/es/mapa-del-sitio/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/sitemap/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sitemap-slug___uk",
    path: "/uk/sitemap/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/sitemap/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sitemap-slug___en",
    path: "/sitemap/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/sitemap/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sitemap___es",
    path: "/es/mapa-del-sitio",
    component: () => import("/builds/RunRepeat/rr_front/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___uk",
    path: "/uk/sitemap",
    component: () => import("/builds/RunRepeat/rr_front/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/builds/RunRepeat/rr_front/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "size-charts-slug___es",
    path: "/es/tabla-de-tallas/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/size-charts/[slug].vue").then(m => m.default || m)
  },
  {
    name: "size-charts-slug___uk",
    path: "/uk/size-charts/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/size-charts/[slug].vue").then(m => m.default || m)
  },
  {
    name: "size-charts-slug___en",
    path: "/size-charts/:slug()",
    component: () => import("/builds/RunRepeat/rr_front/pages/size-charts/[slug].vue").then(m => m.default || m)
  },
  {
    name: "size-charts___es",
    path: "/es/tabla-de-tallas",
    component: () => import("/builds/RunRepeat/rr_front/pages/size-charts/index.vue").then(m => m.default || m)
  },
  {
    name: "size-charts___uk",
    path: "/uk/size-charts",
    component: () => import("/builds/RunRepeat/rr_front/pages/size-charts/index.vue").then(m => m.default || m)
  },
  {
    name: "size-charts___en",
    path: "/size-charts",
    component: () => import("/builds/RunRepeat/rr_front/pages/size-charts/index.vue").then(m => m.default || m)
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe",
    component: () => import("/builds/RunRepeat/rr_front/pages/unsubscribe.vue").then(m => m.default || m)
  }
]