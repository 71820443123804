<template>
  <TheModal
    v-model:is-open="languageModalOpened"
    :title="messages[anotherLocale].title"
    class="language-modal"
  >
    <div class="language-popup">
      <p v-html="messages[anotherLocale].select_text"></p>
      <div class="language-popup-options">
        <a v-for="option in localeOptions"
           v-on:click.prevent="switchLocale(option)"
           :href="option.url"
           class="btn btn-default"
           v-text="option.name"
        ></a>
      </div>
      <p v-html="messages[anotherLocale].bottom_text"></p>
    </div>
  </TheModal>
</template>

<script setup>
import userLocale from '~/utils/userLocale';
import { useMainStore } from "~/stores/main";
import { usePageStore } from "~/stores/page";
import { nextTick } from "vue";

const main = useMainStore();
const page = usePageStore();

const { localeProperties, locales } = useI18n();

const messages = {
  en: {
    title: 'Several languages available',
    select_text: 'RunRepeat is also available in English, please select your preferred language:',
    bottom_text: 'You can always change the preferred language at the bottom of the website.',
  },
  es: {
    title: 'Opciones de idioma',
    select_text: 'RunRepeat también está disponible en español. Escoge tu idioma preferido.',
    bottom_text: 'Siempre puedes cambiar el idioma en la parte inferior de la página.',
  },
}

const anotherLocale = ref('en');

const languageModalOpened = ref(false);

const localeOptions = computed(() => {
  const options = [];

  locales.value.forEach(locale => {
    if (locale.language !== 'en-GB' && page.localeUrls.has(locale.language)) {
      options.push({
        name: locale.name,
        value: locale.language,
        url: page.localeUrls.get(locale.language),
      });

      if (locale.language.slice(0, 2) !== localeProperties.value.language.slice(0, 2)
        && messages[locale.language.slice(0, 2)]
      ) {
        anotherLocale.value = locale.language.slice(0, 2);
      }
    }
  });

  return options.reverse();
});

function switchLocale(locale) {
  userLocale.setSelectedLocale(locale.value);
  languageModalOpened.value = false;

  if (localeProperties.value.language === locale.value
    || localeProperties.value.language.slice(0, 2) === locale.value.slice(0, 2)
  ) {
    return;
  }

  window.location.href = locale.value === 'en' && userLocale.getPreferredLocale() === 'en-GB'
    ? page.localeUrls.get('en-GB')
    : locale.url;
}

onMounted(() => {
  const selectedLocale = userLocale.getSelectedLocale();
  const preferredLocale = userLocale.getPreferredLocale().slice(0, 2);

  const shouldDisplayLanguagePopup = !main.botContinent
    && localeOptions.value.length > 1
    && !selectedLocale
    && preferredLocale
    && preferredLocale !== localeProperties.value.language.slice(0, 2)
    && page.localeUrls.get(preferredLocale);

  if (!shouldDisplayLanguagePopup) {
    return;
  }

  nextTick(() => {
    setTimeout(() => {
      languageModalOpened.value = true;
    }, 2000);
  });
})

</script>

<style lang="scss" scoped>
.language-popup {
  .language-popup-options {
    display: flex;
    flex-basis: max-content;
    justify-content: left;
    gap: 20px;
    margin: 16px 0;

    .btn {
      border: 1px solid $grey-dark;
      border-radius: 2px;
      padding: 7px 14px;
      white-space: nowrap;
      text-align: center;
      color: #000;
      font-weight: 300;

      &:hover,
      &.active {
        @extend %hover-background-orange;
        cursor: pointer;
      }
    }
  }
}
</style>