<template>
  <svg
    width="100%"
    height="100%"
    class="logo-main logo-main-white"
    viewBox="0 0 155 50"
  >
    <g>
      <path
        class="st-logo"
        d="M0,16.5h9.8c1.4,0,2.6,0.2,3.7,0.6c1.1,0.4,2,0.9,2.7,1.6c0.6,0.6,1,1.3,1.3,2.1
                c0.3,0.8,0.5,1.7,0.5,2.6v0.1c0,0.9-0.1,1.7-0.4,2.4c-0.3,0.7-0.6,1.4-1.1,1.9c-0.5,0.6-1,1-1.6,1.4c-0.6,0.4-1.3,0.7-2.1,0.9
                l5.9,8.3h-4.5l-5.4-7.6H8.6H3.8v7.6H0V16.5z M9.5,27.4c1.4,0,2.5-0.3,3.3-1c0.8-0.7,1.2-1.6,1.2-2.7v-0.1c0-1.2-0.4-2.1-1.2-2.7
                C12,20.3,10.9,20,9.5,20H3.8v7.4H9.5z"
      />
      <path
        class="st-logo"
        d="M26.3,38.8c-1.9,0-3.3-0.6-4.3-1.7c-1-1.1-1.5-2.7-1.5-4.6V21.9h3.8v9.4c0,1.3,0.3,2.3,0.9,3
                c0.6,0.7,1.5,1,2.6,1c1.1,0,2-0.4,2.7-1.1c0.7-0.7,1-1.7,1-3v-9.3h3.8v16.5h-3.8v-2.6c-0.5,0.8-1.2,1.5-2,2.1
                C28.7,38.5,27.6,38.8,26.3,38.8z"
      />
      <path
        class="st-logo"
        d="M38.5,21.9h3.8v2.6c0.3-0.4,0.6-0.8,0.9-1.1c0.3-0.3,0.7-0.7,1.1-0.9c0.4-0.3,0.9-0.5,1.4-0.6
                c0.5-0.2,1.1-0.2,1.7-0.2c1.9,0,3.3,0.6,4.3,1.7c1,1.1,1.5,2.7,1.5,4.6v10.5h-3.8v-9.4c0-1.3-0.3-2.3-0.9-3c-0.6-0.7-1.5-1-2.6-1
                c-1.1,0-2,0.4-2.7,1.1c-0.7,0.7-1,1.7-1,3v9.3h-3.8V21.9z"
      />
      <path
        class="st-logo"
        d="M57.3,16.5h9.2c1.4,0,2.6,0.2,3.6,0.6c1,0.4,1.9,0.9,2.6,1.6c0.5,0.5,0.9,1.1,1.2,1.8
                c0.3,0.7,0.4,1.4,0.4,2.2v0.1c0,0.9-0.2,1.7-0.5,2.5c-0.3,0.7-0.8,1.3-1.3,1.9c-0.6,0.5-1.3,0.9-2,1.3c-0.8,0.3-1.6,0.5-2.6,0.7
                l7.2,9.4h-2.1l-7-9.1H66h-7.1v9.1h-1.6V16.5z M66.3,27.9c0.9,0,1.8-0.1,2.6-0.3c0.8-0.2,1.5-0.6,2-1c0.6-0.4,1-1,1.3-1.6
                c0.3-0.6,0.5-1.3,0.5-2.1v-0.1c0-1.5-0.5-2.7-1.6-3.5c-1.1-0.9-2.6-1.3-4.7-1.3h-7.5v9.9H66.3z"
      />
      <path
        class="st-logo"
        d="M84.5,38.8c-1,0-2-0.2-3-0.6c-1-0.4-1.8-1-2.5-1.7c-0.7-0.7-1.3-1.6-1.7-2.6
                c-0.4-1-0.6-2.2-0.6-3.4v-0.1c0-1.1,0.2-2.2,0.6-3.2c0.4-1,0.9-1.9,1.6-2.6c0.7-0.8,1.5-1.3,2.4-1.8c0.9-0.4,1.9-0.7,3-0.7
                c1.1,0,2.1,0.2,3,0.6c0.9,0.4,1.7,1,2.3,1.8c0.6,0.8,1.1,1.6,1.5,2.6c0.3,1,0.5,2.1,0.5,3.3c0,0.1,0,0.3,0,0.3c0,0.1,0,0.2,0,0.3
                H78.2c0.1,1,0.3,1.9,0.7,2.7c0.4,0.8,0.8,1.4,1.4,2c0.6,0.5,1.2,1,1.9,1.2c0.7,0.3,1.5,0.4,2.3,0.4c1.3,0,2.3-0.2,3.2-0.7
                c0.9-0.5,1.6-1.1,2.3-1.8l1.1,0.9c-0.8,0.9-1.7,1.6-2.8,2.2C87.3,38.6,86,38.8,84.5,38.8z M89.9,29.7c-0.1-0.8-0.2-1.6-0.5-2.3
                c-0.3-0.7-0.6-1.4-1.1-2c-0.5-0.6-1.1-1-1.7-1.4c-0.7-0.3-1.5-0.5-2.4-0.5c-0.8,0-1.5,0.2-2.2,0.5c-0.7,0.3-1.3,0.7-1.8,1.3
                c-0.5,0.6-0.9,1.2-1.3,2c-0.3,0.8-0.5,1.6-0.6,2.5H89.9z"
      />
      <path
        class="st-logo"
        d="M94.1,22.5h1.5v3.6c0.3-0.5,0.7-1,1.2-1.5c0.4-0.5,0.9-0.9,1.5-1.3c0.5-0.4,1.1-0.6,1.8-0.9
                c0.7-0.2,1.4-0.3,2.2-0.3c1,0,1.9,0.2,2.8,0.6c0.9,0.4,1.8,0.9,2.5,1.6c0.7,0.7,1.3,1.6,1.8,2.6c0.4,1,0.7,2.2,0.7,3.5v0.1
                c0,1.3-0.2,2.5-0.7,3.5c-0.4,1-1,1.9-1.8,2.6c-0.7,0.7-1.6,1.3-2.5,1.7c-0.9,0.4-1.9,0.6-2.8,0.6c-0.8,0-1.5-0.1-2.2-0.3
                c-0.7-0.2-1.3-0.5-1.8-0.8c-0.5-0.4-1-0.8-1.4-1.2C96.4,36,96,35.5,95.7,35v8.5h-1.5V22.5z M102.1,37.4c0.9,0,1.7-0.2,2.5-0.5
                c0.8-0.3,1.4-0.8,2-1.3c0.6-0.6,1-1.3,1.3-2.1c0.3-0.8,0.5-1.8,0.5-2.9v-0.1c0-1-0.2-2-0.5-2.9c-0.3-0.9-0.8-1.6-1.4-2.2
                c-0.6-0.6-1.2-1.1-2-1.4c-0.8-0.3-1.6-0.5-2.4-0.5c-0.8,0-1.6,0.2-2.4,0.5c-0.8,0.3-1.5,0.8-2.1,1.4c-0.6,0.6-1.1,1.3-1.5,2.2
                c-0.4,0.8-0.5,1.8-0.5,2.8v0.1c0,1,0.2,2,0.5,2.8c0.4,0.8,0.9,1.6,1.5,2.2c0.6,0.6,1.3,1.1,2.1,1.4
                C100.5,37.2,101.3,37.4,102.1,37.4z"
      />
      <path
        class="st-logo"
        d="M120.3,38.8c-1,0-2-0.2-3-0.6c-1-0.4-1.8-1-2.5-1.7c-0.7-0.7-1.3-1.6-1.7-2.6
                c-0.4-1-0.6-2.2-0.6-3.4v-0.1c0-1.1,0.2-2.2,0.6-3.2c0.4-1,0.9-1.9,1.6-2.6c0.7-0.8,1.5-1.3,2.4-1.8c0.9-0.4,1.9-0.7,3-0.7
                c1.1,0,2.1,0.2,3,0.6c0.9,0.4,1.7,1,2.3,1.8c0.6,0.8,1.1,1.6,1.5,2.6c0.3,1,0.5,2.1,0.5,3.3c0,0.1,0,0.3,0,0.3c0,0.1,0,0.2,0,0.3
                h-13.3c0.1,1,0.3,1.9,0.7,2.7c0.4,0.8,0.8,1.4,1.4,2c0.6,0.5,1.2,1,1.9,1.2c0.7,0.3,1.5,0.4,2.3,0.4c1.3,0,2.3-0.2,3.2-0.7
                c0.9-0.5,1.6-1.1,2.3-1.8l1.1,0.9c-0.8,0.9-1.7,1.6-2.8,2.2C123.1,38.6,121.8,38.8,120.3,38.8z M125.7,29.7
                c-0.1-0.8-0.2-1.6-0.5-2.3c-0.3-0.7-0.6-1.4-1.1-2c-0.5-0.6-1.1-1-1.7-1.4c-0.7-0.3-1.5-0.5-2.4-0.5c-0.8,0-1.5,0.2-2.2,0.5
                c-0.7,0.3-1.3,0.7-1.8,1.3c-0.5,0.6-0.9,1.2-1.3,2c-0.3,0.8-0.5,1.6-0.6,2.5H125.7z"
      />
      <path
        class="st-logo"
        d="M135.7,38.8c-0.8,0-1.5-0.1-2.2-0.3c-0.7-0.2-1.4-0.5-2-0.9c-0.6-0.4-1.1-0.9-1.4-1.6
                c-0.4-0.6-0.5-1.4-0.5-2.2v-0.1c0-0.8,0.2-1.6,0.5-2.2c0.3-0.6,0.8-1.2,1.4-1.6c0.6-0.4,1.3-0.8,2.2-1c0.9-0.2,1.8-0.3,2.9-0.3
                c1.1,0,2.1,0.1,2.9,0.2c0.8,0.1,1.6,0.3,2.4,0.5v-0.7c0-1.6-0.5-2.8-1.4-3.6c-0.9-0.8-2.2-1.2-3.9-1.2c-1,0-1.9,0.1-2.7,0.4
                c-0.8,0.2-1.6,0.5-2.3,0.9l-0.6-1.3c0.9-0.4,1.8-0.8,2.7-1c0.9-0.3,1.9-0.4,3-0.4c2.2,0,3.9,0.6,5,1.8c1.1,1.1,1.6,2.6,1.6,4.5v10
                h-1.5v-2.7c-0.3,0.4-0.6,0.7-1,1.1c-0.4,0.4-0.8,0.7-1.3,1c-0.5,0.3-1.1,0.5-1.7,0.7C137.2,38.7,136.5,38.8,135.7,38.8z
                    M135.8,37.4c0.8,0,1.6-0.1,2.3-0.4c0.7-0.2,1.4-0.6,1.9-1c0.6-0.4,1-0.9,1.3-1.5c0.3-0.6,0.5-1.3,0.5-2v-1.8
                c-0.7-0.2-1.5-0.3-2.4-0.5c-0.9-0.2-1.9-0.2-3.1-0.2c-1.7,0-3,0.3-3.9,1c-0.9,0.7-1.4,1.6-1.4,2.7v0.1c0,0.6,0.1,1.1,0.4,1.5
                c0.3,0.4,0.6,0.8,1,1.1c0.4,0.3,0.9,0.6,1.5,0.7C134.6,37.3,135.2,37.4,135.8,37.4z"
      />
      <path
        class="st-logo"
        d="M152.3,38.7c-0.6,0-1.2-0.1-1.8-0.2c-0.5-0.2-1-0.4-1.4-0.8c-0.4-0.4-0.7-0.8-1-1.4
                c-0.2-0.6-0.3-1.2-0.3-2V23.9h-2.3v-1.4h2.3v-5.1h1.5v5.1h5.6v1.4h-5.6v10.3c0,1.1,0.3,1.9,0.9,2.4c0.6,0.5,1.4,0.7,2.3,0.7
                c0.4,0,0.8,0,1.1-0.1c0.4-0.1,0.8-0.2,1.2-0.4v1.4c-0.4,0.2-0.8,0.3-1.2,0.4C153.3,38.7,152.8,38.7,152.3,38.7z"
      />
    </g>
  </svg>
</template>

<style scoped>
.st-logo {
  fill: #ffffff;
}
</style>
