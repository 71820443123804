<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify"
    viewBox="0 0 30 30.000001"
    :width="width"
    :height="height"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
  >
    <defs>
      <clipPath id="id1">
        <path
          d="M 2.652344 5.867188 L 26.605469 5.867188 L 26.605469 23.289062 L 2.652344 23.289062 Z M 2.652344 5.867188 "
          clip-rule="nonzero"
        />
      </clipPath>
      <clipPath id="id2">
        <path
          d="M 12 5.867188 L 26.605469 5.867188 L 26.605469 23.289062 L 12 23.289062 Z M 12 5.867188 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#id1)">
      <path
        fill="rgb(0%, 39.99939%, 0%)"
        d="M 26.601562 20.609375 C 26.601562 22.089844 25.410156 23.289062 23.941406 23.289062 L 5.316406 23.289062 C 3.847656 23.289062 2.65625 22.089844 2.65625 20.609375 L 2.65625 8.546875 C 2.65625 7.070312 3.847656 5.867188 5.316406 5.867188 L 23.941406 5.867188 C 25.410156 5.867188 26.601562 7.070312 26.601562 8.546875 Z M 26.601562 20.609375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
    <g clip-path="url(#id2)">
      <path
        fill="rgb(83.529663%, 16.859436%, 11.759949%)"
        d="M 23.941406 5.867188 L 12.632812 5.867188 L 12.632812 23.289062 L 23.941406 23.289062 C 25.410156 23.289062 26.601562 22.089844 26.601562 20.609375 L 26.601562 8.546875 C 26.601562 7.070312 25.410156 5.867188 23.941406 5.867188 Z M 23.941406 5.867188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
    <path
      fill="rgb(100%, 79.998779%, 30.198669%)"
      d="M 12.632812 9.21875 C 9.695312 9.21875 7.3125 11.617188 7.3125 14.578125 C 7.3125 17.539062 9.695312 19.9375 12.632812 19.9375 C 15.570312 19.9375 17.953125 17.539062 17.953125 14.578125 C 17.953125 11.617188 15.570312 9.21875 12.632812 9.21875 Z M 15.289062 13.195312 L 16.679688 12.261719 C 17.003906 12.839844 17.210938 13.496094 17.269531 14.195312 Z M 13.296875 18.597656 L 15.023438 18.597656 C 14.503906 18.910156 13.921875 19.125 13.296875 19.214844 Z M 7.996094 14.195312 C 8.050781 13.507812 8.25 12.867188 8.566406 12.296875 L 9.632812 13.371094 Z M 7.996094 14.960938 L 10.488281 16.21875 L 10.785156 15.617188 L 8.71875 14.578125 L 10.128906 13.871094 L 11.066406 14.816406 L 11.535156 14.339844 L 10.753906 13.554688 L 10.785156 13.539062 L 10.488281 12.9375 L 10.257812 13.054688 L 9.609375 12.402344 L 10.582031 12.5625 L 10.691406 11.902344 L 9.019531 11.621094 C 9.738281 10.730469 10.78125 10.113281 11.96875 9.941406 L 11.96875 17.929688 L 9.90625 17.929688 L 11.484375 16.867188 L 11.117188 16.308594 L 9.121094 17.648438 L 9.132812 17.664062 C 8.492188 16.929688 8.078125 15.992188 7.996094 14.960938 Z M 11.96875 19.214844 C 11.34375 19.125 10.761719 18.910156 10.242188 18.597656 L 11.96875 18.597656 Z M 16.148438 17.648438 L 14.199219 15.679688 L 13.726562 16.15625 L 14.042969 16.472656 L 13.777344 16.867188 L 15.359375 17.929688 L 13.296875 17.929688 L 13.296875 9.941406 C 14.175781 10.070312 14.976562 10.4375 15.625 10.988281 L 13.859375 11.582031 L 14.066406 12.214844 L 16.0625 11.546875 L 15.996094 11.339844 C 16.105469 11.457031 16.210938 11.578125 16.308594 11.707031 L 14.441406 12.960938 L 14.628906 13.238281 L 14.480469 13.539062 L 16.546875 14.578125 L 14.480469 15.617188 L 14.777344 16.21875 L 17.269531 14.960938 C 17.1875 15.984375 16.777344 16.914062 16.148438 17.648438 Z M 16.148438 17.648438 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="rgb(83.529663%, 16.859436%, 11.759949%)"
      d="M 9.972656 11.226562 L 9.972656 15.917969 C 9.972656 17.398438 11.164062 18.597656 12.632812 18.597656 C 14.101562 18.597656 15.292969 17.398438 15.292969 15.917969 L 15.292969 11.226562 Z M 9.972656 11.226562 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="rgb(100%, 100%, 100%)"
      d="M 10.636719 11.898438 L 10.636719 15.917969 C 10.636719 17.027344 11.53125 17.929688 12.632812 17.929688 C 13.734375 17.929688 14.628906 17.027344 14.628906 15.917969 L 14.628906 11.898438 Z M 10.636719 11.898438 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="rgb(50.979614%, 60.389709%, 80.389404%)"
      d="M 11.300781 13.90625 L 13.960938 13.90625 L 13.960938 15.25 L 11.300781 15.25 Z M 11.300781 13.90625 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="rgb(50.979614%, 60.389709%, 80.389404%)"
      d="M 11.96875 13.238281 L 13.296875 13.238281 L 13.296875 15.917969 L 11.96875 15.917969 Z M 11.96875 13.238281 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="rgb(0%, 19.999695%, 59.999084%)"
      d="M 10.636719 13.90625 L 11.300781 13.90625 L 11.300781 15.25 L 10.636719 15.25 Z M 11.96875 13.90625 L 13.296875 13.90625 L 13.296875 15.25 L 11.96875 15.25 Z M 13.960938 13.90625 L 14.628906 13.90625 L 14.628906 15.25 L 13.960938 15.25 Z M 11.96875 15.917969 L 13.296875 15.917969 L 13.296875 17.257812 L 11.96875 17.257812 Z M 11.96875 11.898438 L 13.296875 11.898438 L 13.296875 13.238281 L 11.96875 13.238281 Z M 11.96875 11.898438 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '40px',
    },

    height: {
      type: String,
      default: '40px',
    },
  },
};
</script>
