import VueGtag, {
  event,
  pageview,
  screenview
} from "vue-gtag";
import { GA_GTAG_ID, GA_OPTIMIZE_ID, GA_EXPERIMENT_COOKIE } from '~/constants/global';
import { useMainStore } from "~/stores/main";
// import { useExperimentStore } from "~/stores/experiment";

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const main = useMainStore();
  // const experiment = useExperimentStore();

  if (route.query.uptime_test || (!main.isProd && !route.query.test_GA)) {
    return;
  }

  const options = {
    config: {
      id: GA_GTAG_ID,
      params: {
        send_page_view: false,
      },
    },
    pageTrackerEnabled: false,
    onReady() {
      // experiment.setGaReady();
    },
    enabled: (main.isProd || !!route.query.test_GA) && !route.query.uptime_test,
  };

  nuxtApp.vueApp.use(VueGtag, options);

  return {
    provide: {
      gtag: {
        event,
        pageview,
        screenview,
      }
    }
  };
});
