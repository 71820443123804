export const useSearchStore = defineStore('search', {
  state: () => ({
    suggestions: [],
  }),

  actions: {
    async suggest({ query, onlyShoes, category }) {
      const version = onlyShoes ? '1' : '2';
      let params = { q: query, version };
      if (category) {
        params.c_id = category;
      }
      const data = await $api('/suggest.php', {
        params,
        responseType: 'json',
      });

      this.suggestions = version === '1'
        ? // Version results format is different then the second one,
          // so we need to map
        data.map(shoe => ({
          image: shoe.img,
          value: shoe.name,
          ...shoe,
        }))
        : data;

      if (version === '2' && this.suggestions.length > 0) {
        const searchLink = useNuxtApp().$localePath({ name: 'search', query: {q: query} })
        const { t } = useNuxtApp().$i18n;

        this.suggestions.push({
          value: t('partials.all_search_results', { query }),
          link: searchLink,
          directLink: searchLink,
        });
      }
    },
    reset() {
      this.suggestions = [];
    },
  },
});
