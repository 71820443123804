import * as Sentry from '@sentry/nuxt';

Sentry.init({
  // useRoute().query.disable_sentry
  enabled: !!process.env.SENTRY_DSN,
  debug: import.meta.env.DEV || import.meta.env.CI,
  dsn: process.env.SENTRY_DSN,

  defaultIntegrations: false,
  integrations: [],
  trackComponents: true,
  // hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
  allowUrls: [/https?:\/\/((cdn|www|assets|api)\.)?runrepeat\.com/],
  ignoreErrors: [
    /ReportingObserver \[deprecation\]/,
    /NotAllowedError/,
    /AbortError/,
    /reading '_transitionClasses'/,
    /UnhandledRejection/,
  ],

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/runrepeat.com/],

  sampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});
