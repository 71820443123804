<template>
  <div class="language-switcher" :class="{ hide: localeOptions.length < 2 }">
    <LanguageIcon />
    <template v-for="(option, index) in localeOptions">
      <span v-if="index !== 0"> |</span>
      <a
        :href="option.url"
        @click="switchLocale(option)"
        :class="{ active: option.value === currentValue }"
      >
        <span v-text="option.name"></span>
      </a>
    </template>
  </div>
</template>

<script setup>
import LanguageIcon from '~/components/icons/awesome-font/LanguageIcon';
import userLocale from '~/utils/userLocale';
import { usePageStore } from "~/stores/page";

const { localeProperties, locales } = useI18n();

const currentValue = localeProperties.language;

const page = usePageStore();

const localeOptions = computed(() => {
  const options = [];
  locales.value.forEach(locale => {
    if (locale.language !== 'en-GB' && page.localeUrls.has(locale.language)) {
      options.push({
        name: locale.name,
        value: locale.language,
        url: page.localeUrls.get(locale.language),
      });
    }
  });

  return options.reverse();
});

function switchLocale(locale) {
  userLocale.setSelectedLocale(locale.value);
  if (locale.value !== currentValue) {
    window.location.href = locale.url;
  }
}

</script>

<style lang="scss" scoped>
.language-switcher {
  display: inline-block;
  a {
    margin: 0 4px;
    // color: rgba(255, 255, 255, 0.35);
    color: #fff;
    &:hover, &:visited {
      color: #fff;
      text-decoration: none;
    }
    &.active {
      font-weight: 400;
      cursor: not-allowed;
    }
  }
}
</style>
