<template>
  <svg class="hamburger-icon" width="100%" height="100%" viewBox="36 21 30 20">
    <g class="hamburger">
      <rect x="28" y="21.5" class="hamburger" width="30" height="3" />
      <rect x="28" y="29.5" class="hamburger" width="30" height="3" />
      <rect x="28" y="37.5" class="hamburger" width="30" height="3" />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.hamburger-icon {
  max-width: 33px;
  max-height: 24px;
  .hamburger {
    fill: #ffffff;
  }
}
</style>
