import { GA_EXPERIMENT_COOKIE } from '~/constants/global';
import { useMainStore } from '~/stores/main';
import { useCookie } from '#app';
import { useExperimentStore } from '~/stores/experiment';

export default defineNuxtPlugin(nuxtApp => {
  const route = useRoute();
  const main = useMainStore();
  const experiment = useExperimentStore();

  if (route.query.uptime_test || (!main.isProd && !route.query.test_GA)) {
    return;
  }

  if (experiment.id && experiment.isBackend && Number.isInteger(experiment.forcedVariation)) {
    const cookie = useCookie(GA_EXPERIMENT_COOKIE, {
      maxAge: 86400,
      path: '/',
    });
    cookie.value = experiment.forcedVariation;
  }
});
