<template>
  <svg
    width="100%"
    height="100%"
    viewBox="27 60 37 47"
    style="max-width: 17px; max-height: 23px"
  >
    <g id="search">
      <path
        class="search"
        d="M60.6,94.3l-8.4-8.8c2.2-2.6,3.4-5.8,3.4-9.2c0-7.9-6.4-14.3-14.3-14.3S27,68.4,27,76.3
	s6.4,14.3,14.3,14.3c3,0,5.8-0.9,8.2-2.6l8.5,8.8c0.4,0.4,0.8,0.6,1.3,0.6c0.5,0,0.9-0.2,1.3-0.5C61.3,96.2,61.4,95,60.6,94.3z
	 M41.3,65.7c5.8,0,10.6,4.7,10.6,10.6c0,5.8-4.7,10.6-10.6,10.6s-10.6-4.7-10.6-10.6C30.7,70.5,35.4,65.7,41.3,65.7z"
      />
    </g>
  </svg>
</template>

<style scoped>
.search {
  fill: #ffffff;
}
</style>
