<template>
  <header class="site-header nav-down">
    <TheHeaderAnnouncement
      :link="localePath({ name: 'slug', params: { slug: hiringSlug } })"
      :text="hiringText"
      v-show="countryToDisplayJobAd"
    />
    <nav class="navbar navbar-inverse" :class="{ 'navbar-transparent': transparent }">
      <div
        class="container"
        :class="{ 'container-wide': wideContainer, 'container-fixed': fixedContainer }"
      >
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button
            type="button"
            :aria-label="$t('generic.main_menu')"
            class="navbar-categories-toggle navbar-toggle collapsed"
            data-toggle="collapse"
            @click.prevent="buttonCategoriesToggle = !buttonCategoriesToggle"
          >
            <!-- svg icon -->
            <Humburger />
            <!-- end svg icon -->
          </button>
          <button
            type="button"
            :aria-label="$t('generic.search')"
            class="home-search-toggle search-toggle"
            @click.prevent="openSearch"
          >
            <!-- svg icon -->
            <Search />
            <!-- end svg icon -->
          </button>
          <a
            class="navbar-brand"
            :href="localePath({ name: 'index' })"
            :aria-label="$t('generic.homepage')"
          >
            <LogoWhite />
          </a>
        </div>

        <MainSearch
          :value="query"
          :is-active="searchOpen"
          :class="{ expanded: searchOpen }"
          @disactivated="closeSearch"
        />

        <transition
          name="categories-toggle"
          @enter="onEnterHeaderCategoriesToggle"
          @afterEnter="onAfterEnterHeaderCategoriesToggle"
          @leave="onLeaveHeaderCategoriesToggle"
          @afterLeave="onAfterLeaveHeaderCategoriesToggle"
        >
          <TheHeaderCategoriesNavbar
            :class="{ 'hidden-sm hidden-xs': !showCategorieMenuMobile }"
            @categoryLinkClicked="categoryLinkClicked"
          />
        </transition>
      </div>
    </nav>
    <LanguagePopup />
  </header>
</template>

<script>
import LogoWhite from '~/components/icons/LogoWhite.vue';
import Humburger from '~/components/icons/Humburger.vue';
import Search from '~/components/icons/Search.vue';
import MainSearch from '~/components/shared/search/MainSearch';
import TheHeaderCategoriesNavbar from '~/components/shared/layout/TheHeaderCategoriesNavbar.vue';
import TheHeaderAnnouncement from '~/components/shared/layout/TheHeaderAnnouncement';
import LanguagePopup from '~/components/shared/layout/LanguagePopup';

import { mapState } from 'pinia';
import { useRankingFilterStore } from '~/stores/rankingFilter';
import { useMainStore } from '~/stores/main';
import { usePageStore } from '~/stores/page';

export default {
  components: {
    MainSearch,
    LogoWhite,
    Humburger,
    Search,
    TheHeaderCategoriesNavbar,
    TheHeaderAnnouncement,
    LanguagePopup,
  },
  props: {
    transparent: {
      type: Boolean,
      default: false, // @TODO: fix css so that name is transparent.
    },
  },
  data() {
    return {
      searchOpen: false,
      buttonCategoriesToggle: false,
    };
  },
  computed: {
    hiringSlug() {
      const code = this.countryToDisplayJobAd?.code;
      let slug = this.$t('url.hiring');
      if (code === 'es') {
        slug = this.$t('url.hiring_spain');
      }
      if (code === 'pt') {
        slug = this.$t('url.hiring_portugal');
      }
      if (code === 'ph') {
        slug = this.$t('url.hiring_ph');
      }
      return slug;
    },
    hiringText() {
      const code = this.countryToDisplayJobAd?.code;
      let text = this.$t('partials.hiring_text', { country: this.countryToDisplayJobAd?.name });
      if (code === 'es') {
        text = this.$t('partials.hiring_text_spain');
      }
      if (code === 'pt') {
        text = this.$t('partials.hiring_text_portugal');
      }
      if (code === 'ph') {
        text = this.$t('partials.hiring_text_ph');
      }
      return text;
    },
    blackFridaySlug() {
      return 'deals';
    },
    blackFridayText() {
      return 'Black Friday Sale!';
    },
    showCategorieMenuMobile() {
      return this.buttonCategoriesToggle;
    },
    ...mapState(useRankingFilterStore, ['query']),
    ...mapState(useMainStore, ['wideContainer', 'fixedContainer', 'countryToDisplayJobAd']),
    ...mapState(usePageStore, {
      showDeals: store => store.global.show_deals,
    }),
  },
  methods: {
    openSearch() {
      this.searchOpen = true;
    },
    closeSearch() {
      this.searchOpen = false;
    },
    onEnterHeaderCategoriesToggle(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    onAfterEnterHeaderCategoriesToggle(el) {
      el.style.height = 'auto';
    },
    onLeaveHeaderCategoriesToggle(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    onAfterLeaveHeaderCategoriesToggle(el) {
      el.style.height = 0;
    },
    categoryLinkClicked(url) {
      this.buttonCategoriesToggle = false;
      this.$gaEvents.headerLinkClicked(url);
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  padding-top: 80px;
  transition: padding 0.6s ease-in-out;
  header {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    transition: top 0.2s ease-in-out;
    z-index: 1001;
    // :deep(.black-friday) {
    //   @media (min-width: $break_md_upper) {
    //     display: none;
    //   }
    // }
    &.nav-up {
      top: -60px;
    }
    .navbar {
      margin-bottom: 0;
      height: 65px;
      padding: 0px 0 0 0;
      border: none;
      border-bottom: none;
      background-image: none;
      background-color: $dark-blue;
      border-radius: 0;
      // position: relative;
      @media (max-width: $break_sm_under) {
        padding: 15px;
      }
      & > .container {
        position: relative;
      }
      .navbar-brand {
        padding: 0;
        height: 100%;
        margin-left: 0 !important;
        display: inline-block;
        @media (max-width: $break_md_under) {
          float: none;
        }
        @media (max-width: $break_sm_under) {
          padding: 0;
        }
        @media (min-width: $break_sm_upper) {
          margin-left: 0;
        }
        img {
          width: auto;
          max-height: 60px;
          @media (max-width: $break_sm_under) {
            height: 40px;
            width: auto;
            margin-top: -5px;
          }
        }
      }
      .navbar-toggle {
        padding: 0;
        margin: 8px 0 0 0;
        border: none;
        &:hover,
        &:focus {
          background-color: transparent;
        }
        &.collapsed {
          background-color: transparent;
        }
      }

      // Seach button
      .search-toggle,
      .search-toggle:hover,
      .search-toggle:focus,
      .search-toggle:active {
        display: inline-block;
        color: white;
        text-decoration: none;
        background: none;
        border: 0;
        @media (max-width: $break_sm_under) {
          padding-top: 0;
          margin-top: 7px;
        }
        @media (min-width: $break_sm_upper) {
          display: none;
        }
      }
    }
  }
}

nav {
  .navbar-header {
    .home-search-toggle.search-toggle {
      float: left;
    }
    @media (max-width: $break_md_under) {
      text-align: center;
    }
    a.navbar-brand {
      width: 156px;
      height: 55px;
      @media (max-width: $break_sm_under) {
        position: relative;
        width: 100px;
        height: 40px;
      }
    }
    .logo-main {
      @media (max-width: $break_sm_under) {
        position: absolute;
        top: -5px;
        left: 0;
        width: 100px;
        height: 40px;
      }
    }
  }

  .categories-toggle-enter-active,
  .categories-toggle-leave-active {
    @include transition(all 0.2s ease-in);
  }
  .categories-toggle-enter,
  .categories-toggle-leave-to {
    height: 0 !important;
  }

  ul#menu-main > li a.top-level-drop-down {
    @media (min-width: $break_sm_upper+1) {
      color: #fff !important;
    }
    @media (max-width: $break_sm_under+1) {
      color: #000 !important;
    }
  }

  &.navbar-transparent {
    background-color: transparent !important;
    .collapse ul > li > a,
    .navbar .navbar-collapse.collapsing ul > li > a {
      @media (min-width: $break_sm_upper) {
        color: #fff !important;
      }
    }
    .navbar-header {
      a.navbar-brand {
        & > img.logo-main {
          display: none !important;
          visibility: visible !important;
        }
        .logo-main {
          &.logo-main-white {
            display: block !important;
          }
          &.logo-main-dark {
            display: none !important;
          }
        }
      }
    }
  }
}

@media (min-width: $break_sm_upper) and (max-width: $break_md_under) {
  nav.navbar {
    rr-shoes-search-input .header_search {
      background: transparent;
      width: 10%;
      top: -3px;
      position: absolute;
      &.expanded {
        width: 100%;
        right: 0;
      }
    }
  }
  nav.navbar-transparent ul#menu-main li a {
    color: #fff !important;
  }
  .categories-navbar.navbar-collapse {
    &.collapse,
    &.collapsing {
      display: none !important;
      visibility: hidden !important;
      top: 58px;
      position: absolute;
      right: -15px;
      margin: 0;
      padding: 0;
      min-width: 240px;
      background-color: #1a3338;
      &.in {
        display: block !important;
        visibility: visible !important;
      }
      ul {
        padding-top: 0;
        li {
          padding: 0;
          margin: 10px;
          background-color: transparent;
          a {
            padding: 5px 10px;
            border-bottom: 0;
            font-size: 14px;
            &:hover {
              border-bottom: 0;
            }
          }
        }
      }
    }
    &.collapsing {
      // position: relative;
      height: 0;
      overflow: hidden;
      -webkit-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      -webkit-transition-duration: 0.35s;
      -o-transition-duration: 0.35s;
      transition-duration: 0.35s;
      -webkit-transition-property: height, visibility;
      -o-transition-property: height, visibility;
      transition-property: height, visibility;
    }
  }
  .navbar-categories-toggle.navbar-toggle {
    display: block !important;
    top: 15px;
  }
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .home-search-toggle.search-toggle {
    display: block !important;
    position: relative;
    top: 18px;
    margin-right: 20px;
  }
  rr-shoes-search-input {
    .header_search {
      display: none;
    }
  }
}
</style>
