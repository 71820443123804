<template>
  <div id="page" class="site">
    <TheHeader :transparent="headerTransparent" />
    <div id="content" class="site-content">
      <slot name="content"></slot>
      <div :class="containerClasses">
        <TheBreadcrumbs />
        <slot />
      </div>
    </div>
    <TheFooter />
    <GlobalZoom />
  </div>
</template>

<script setup>
import TheHeader from "~/components/shared/layout/TheHeader.vue";
import TheFooter from "~/components/shared/layout/TheFooter.vue";
import TheBreadcrumbs from "~/components/shared/layout/TheBreadcrumbs";
import GlobalZoom from "~/components/core/GlobalZoom.vue";
import { useMainStore } from "~/stores/main";
import { usePageStore } from "~/stores/page";

const props = defineProps({
  containerClasses: {
    type: String,
    default: 'container',
  },
});

const main = useMainStore();
const page = usePageStore();

const headerTransparent = computed(() => main.headerTransparent);
</script>